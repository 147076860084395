import { Component, OnInit } from '@angular/core';
import { ObjectsService } from '../../objects.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddObjectMapComponent } from '../add-object-map/add-object-map.component';
import { HttpErrorHandler } from '../../../common/httpErrorHandler';
import { DialogService } from '../../../common/dialog.service';
import { UtilsService } from '../../../common/services/utils.service';

@Component({
  selector: 'app-object-maps',
  templateUrl: './object-maps.component.html',
  styleUrls: ['./object-maps.component.less']
})
export class ObjectMapsComponent implements OnInit {
  mapID: number;
  maps: any[] = [];
  constructor(
    private service: ObjectsService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private dialogService: DialogService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.mapID = Number(paramMap.get('id'));
      this.load();
    });
  }

  load() {
    if (!this.mapID) {
      return;
    }
    this.service.getMapsForObject(this.mapID)
      .subscribe(maps => {
        this.maps = maps as any[];
      });
  }

  deleteObjectMap(id) {
    this.dialogService.confirmDialog('Are you sure you want to delete this object map?').then(result => {
      if (!!result) {
        this.service.deleteObjectMap(id)
          .subscribe(
            maps => {
              this.load();
            },
            error => {
              this.dialogService.errorMessage(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
            });
      }
    });
  }
}
