import { Component, OnInit, Input } from '@angular/core';
import { FilePair } from '../../../common/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ObjectsService } from '../../objects.service';
import { HttpErrorHandler } from '../../../common/httpErrorHandler';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../../../common/services/utils.service';
import { DialogService } from '../../../common/dialog.service';

@Component({
  selector: 'app-add-object-map',
  templateUrl: './add-object-map.component.html',
  styleUrls: ['./add-object-map.component.less']
})
export class AddObjectMapComponent implements OnInit {
  objectMapId: number;
  model: any = {
    id: 0,
    nameFirst: null,
    nameSecond: null,
    nameThird: null,
    image: null,
    thumbnail: null,
    objectId: 0
  };

  imageFile: File;
  imageFileURL: any;
  thumbnailFile: File;
  thumbnailFileURL: any;
  errors: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private service: ObjectsService,
    private sanitizer: DomSanitizer,
    private utils: UtilsService,
    private router: Router,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.objectMapId = Number(paramMap.get('id'));
      if (!this.objectMapId) {
        this.route.queryParamMap.subscribe(queryMap => {
          this.model.objectId = Number(queryMap.get('objectid'));
        });

        return;
      }

      this.load();
    });
  }

  load() {
    this.service.getObjectMap(this.objectMapId)
      .subscribe(data => {
        this.model = data;
      });
  }

  imageUploaded(files: FileList) {
    this.errors = [];
    if (!files || !files.length) {
      return;
    }

    const file = files[0];
    const errors = this.utils.validateFile(file);
    if (!!errors.length) {
      this.errors.push({ key: 'Image', errors: errors });
      return;
    }

    this.imageFile = file;
    this.imageFileURL = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.imageFile));
  }

  thumbnailUploaded(files: FileList) {
    this.errors = [];
    if (!files || !files.length) {
      return;
    }

    const file = files[0];
    const errors = this.utils.validateFile(file);
    if (!!errors.length) {
      this.errors.push({ key: 'Thumbnail', errors: errors });
      return;
    }

    this.thumbnailFile = file;
    this.thumbnailFileURL = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.thumbnailFile));
  }

  saveObjectMap() {
    this.errors = [];
    const files = [];
    if (!!this.imageFile) {
      files.push(new FilePair('image', this.imageFile));
      this.model.image = this.imageFile.name;
    }

    if (!!this.thumbnailFile) {
      files.push(new FilePair('thumbnail', this.thumbnailFile));
      this.model.thumbnail = this.thumbnailFile.name;
    }

    this.service.saveMapForObject(this.model, files)
      .subscribe(
        data => {
          if (!this.model.id) {
            this.router.navigate(['/object-map-details', data]);
          }

          this.dialogService.successMessage('Object map saved successfully.');
        },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
  }
  
  goBack() {
    this.router.navigateByUrl('/object/' + this.model.objectId);
  }

}
