import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectsService } from '../../../objects.service';
import { HttpErrorHandler } from '../../../../common/httpErrorHandler';

@Component({
  selector: 'app-add-new-row',
  templateUrl: './add-new-row.component.html',
  styleUrls: ['./add-new-row.component.less']
})
export class AddNewRowComponent implements OnInit {
  @Input() objectMapZoneId;

  constructor(
    public activeModal: NgbActiveModal,
    private service: ObjectsService,
  ) { }
  model: any = {
    id: 0,
    endAt: 0,
    rowNumber: 0,
    startAt: 1,
  };
  errors: any[] = [];

  ngOnInit() {
  }
  saveObjectMapZone() {
    this.model.objectMapZoneId = this.objectMapZoneId;
    this.service.saveRowForObjectMapZone(this.model)
      .subscribe(methods => {
        this.activeModal.close(true);
      },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
  }
  closeModal() {
    this.activeModal.close();
  }
}
