import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from 'src/app/common/dialog.service';
import { HttpErrorHandler } from 'src/app/common/httpErrorHandler';
import { EventsService } from '../../events.service';

@Component({
  selector: 'app-add-or-edit-event-link',
  templateUrl: './add-or-edit-event-link.component.html',
  styleUrls: ['./add-or-edit-event-link.component.less']
})
export class AddOrEditEventLinkComponent implements OnInit {
  @Input() eventid;
  @Input() eventlinkid;
  errors: any[] = [];
  model: any = {};
  constructor(private eventService: EventsService,
    public activeModal: NgbActiveModal,
    private dialogService: DialogService) { }
  
  ngOnInit() {
    if(this.eventlinkid != 0){
      this.getEventLink(this.eventlinkid);
    }
  }
  getEventLink(id:number){
    this.eventService.getEventLink(id)
    .subscribe((data: any)=>{
      this.model = data;
    });
  }
  saveEventLink() {
    this.model.eventid = this.eventid;
    this.eventService.saveEventLinksForEvent(this.model).
    subscribe((data: any) => {
      this.dialogService.successMessage('Event link was successfully added!');
        this.activeModal.close(true);
    },
    error => {
      this.errors = HttpErrorHandler.retrieveErrors(error);
    });
  }
  closeDialog() {
    this.activeModal.close();
  }
}
