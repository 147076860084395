import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from 'src/app/common/constants';
import { DialogService } from 'src/app/common/dialog.service';
import { HttpErrorHandler } from 'src/app/common/httpErrorHandler';
import { NotificationsService } from 'src/app/common/notifications.service';
import { EventsService } from '../events.service';
import { AddOrEditEventLinkComponent } from './add-or-edit-event-link/add-or-edit-event-link.component';

@Component({
  selector: 'app-event-links',
  templateUrl: './event-links.component.html',
  styleUrls: ['./event-links.component.less']
})
export class EventLinksComponent implements OnInit {
  eventid: number;
  eventLinks: any[];
  constructor(private service: EventsService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private dialogService: DialogService,
    private notificationService: NotificationsService) { }

  ngOnInit() {
    this.loadEventLinks();
  }
  loadEventLinks(){
    this.route.paramMap.subscribe(paramMap => {
      this.eventid = Number(paramMap.get('id'));
      if (!this.eventid) {
        return;
      }
    });
    this.eventLinks = [];
    this.service.getEventLinks(this.eventid)
      .subscribe(links => {
        this.eventLinks = links as any[];
      });
  }
  openModal(id){
    const modalRef = this.modalService.open(AddOrEditEventLinkComponent);
    modalRef.componentInstance.eventid = this.eventid;
    modalRef.componentInstance.eventlinkid = id;
    modalRef.result.then(result => {
      if (!!result) {
        this.eventLinks = [];
        this.loadEventLinks();
      }
    });
  }
  openDeleteModal(id) {
    this.dialogService.confirmDialog('Are you sure you want to delete this link for this event?')
      .then(result => {
        if (!!result) {
          this.service.deleteEventLinksForEvent(id)
            .subscribe(response => {
              this.loadEventLinks();
              //this.notificationService.notify.emit(Constant.TICKETS_GENERATED_NOTIFICATION);
            }, error => {
              this.dialogService.errorMessage(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
            });
        }
      });
  }

}
