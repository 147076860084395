import { Injectable } from '@angular/core';
import { DataService } from '../common/data.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SellPeriodsService extends DataService {
  public url = 'api/sellperiod';
  constructor(http: HttpClient) {
    super(http);
    //, 'api/sellperiod'
  }
  private sellPeriodId: number;
  sellPeriodIdChanged = new Subject<number>();

  saveSellPeriod(model: any) {
    return this.http.post(this.url + '/saveSellPeriod', model);
  }
  deleteSellPeriod(sellperiodid) {
    return this.http.get(this.url + '/' + sellperiodid + '/deletesellperiod');
  }
 
}
