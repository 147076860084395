import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-field-errors',
  templateUrl: './field-errors.component.html',
  styleUrls: ['./field-errors.component.less']
})
export class FieldErrorsComponent {
  @Input('field') field: string;
  fieldErrors = [];

  @Input('errors') set errors(value: any[]) {
    this.fieldErrors = [];
    const key = this.field || null;
    if (!value || !value.length) {
      return;
    }

    for (const err of value) {
      if ((!key && !err['key']) || err['key'] === key) {
        this.fieldErrors = err['errors'];
        break;
      }
    }
  }

  constructor() { }
}
