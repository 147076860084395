import { Component, OnInit } from '@angular/core';
import { VirtualEventsService } from './virtual-events.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '../common/dialog.service';
import { HttpErrorHandler } from '../common/httpErrorHandler';
import { AddReaderToVirtualEventComponent } from './add-reader-to-virtual-event/add-reader-to-virtual-event.component';
import { AddEventToVirtualEventComponent } from './add-event-to-virtual-event/add-event-to-virtual-event.component';
import { SaveVirtualEventComponent } from './save-virtual-event/save-virtual-event.component';

@Component({
  selector: 'app-virtual-events',
  templateUrl: './virtual-events.component.html',
  styleUrls: ['./virtual-events.component.less']
})
export class VirtualEventsComponent implements OnInit {

  data: any = {
    records: []
  };

  filter: any = {
    page: 1,
    size: 10,
    term: null
  };

  constructor(private service: VirtualEventsService,
    private modalService: NgbModal,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.service.getAll(this.filter)
      .subscribe(data => {
        this.data = data;
      });
  }

  search() {
    this.filter.page = 1;
    this.load();
  }

  clear() {
    this.filter.page = 1;
    this.filter.term = null;
    this.load();
  }

  delete(itm) {
    this.dialogService.confirmDialog('Are you sure you want to delete this virtual event?')
      .then(response => {
        if (!!response) {
          this.service.delete(itm.id)
            .subscribe(firms => {
              this.load();
            }, error => {
              this.dialogService.errorMessage(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
            });
        }
      });
  }

  saveVirtualEvent(evnt) {
    const modalRef = this.modalService.open(SaveVirtualEventComponent);
    if (!!evnt) {
      modalRef.componentInstance.virtualEvent = evnt;
    }

    modalRef.result.then(result => {
      if (!!result) {
        this.load();
      }
    });
  }

  addReader(itm) {
    const modalRef = this.modalService.open(AddReaderToVirtualEventComponent);
    modalRef.componentInstance.virtualEvent = itm;
    modalRef.result.then(result => {
      if (!!result) {
        this.load();
      }
    });
  }

  addEvent(itm) {
    const modalRef = this.modalService.open(AddEventToVirtualEventComponent);
    modalRef.componentInstance.virtualEvent = itm;
    modalRef.result.then(result => {
      if (!!result) {
        this.load();
      }
    });
  }

}
