import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService, FilePair } from '../common/data.service';

@Injectable()
export class EventsService extends DataService {

  public url = 'api/events';
  constructor(http: HttpClient) {
    super(http);
    // , 'api/events'
  }

  lookup() {
    return this.http.get(this.url + '/lookup');
  }

  getShippingMethods(id) {
    return this.http.get(this.url + '/shippingmethods/' + id);
  }

  getEventFirms(id) {
    return this.http.get(this.url + '/firms/' + id);
  }

  getEventZones(eventid: number, periodid: number) {
    return this.http.get(this.url + '/' + eventid + '/period/' + periodid + '/zones');
  }

  getRowsForZone(eventid: number, zoneid: number, periodid: number) {
    return this.http.get(this.url + '/' + eventid + '/zone/' + zoneid + '/period/' + periodid + '/rows');
  }

  getSeatsForRow(eventid: number, zoneid: number, rowid: number, periodid: number) {
    return this.http.get(this.url + '/' + eventid + '/zone/' + zoneid + '/row/' + rowid + '/period/' + periodid + '/seats');
  }

  setSeatPrice(model: any) {
    return this.http.post(this.url + '/setseatprice', model);
  }

  setRowPrice(model: any) {
    return this.http.post(this.url + '/setrowprice', model);
  }

  setZonePrice(model: any) {
    return this.http.post(this.url + '/setzoneprice', model);
  }

  setSeatStatus(model: any) {
    return this.http.post(this.url + '/setseatstatus', model);
  }

  setRowStatus(model: any) {
    return this.http.post(this.url + '/setrowstatus', model);
  }

  setZoneStatus(model: any) {
    return this.http.post(this.url + '/setzonestatus', model);
  }

  saveEvent(model: any, files: FilePair[]) {
    return super.postFile(this.url + '/saveEvent', model, files);
  }

  uploadTicketNumbers(model: any, files: FilePair[]) {
    return super.postFile(this.url + '/uploadTicketNumbers', model, files);
  }

  getSeatStatuses() {
    return this.http.get(this.url + '/getseatstatuses');
  }

  saveShippingMethods(model: any) {
    return this.http.post(this.url + '/saveEventShippingMethods', model);
  }

  deleteShippingMethodForEvent(id: number) {
    return this.http.post(this.url + '/deleteshippingmethodforevent', id);
  }

  getFirmsForEvent(eventid: number) {
    return this.http.get(this.url + '/' + eventid + '/getfirmsforevent');
  }

  saveFirmsForEvent(model: any) {
    return this.http.post(this.url + '/saveFirmsForEvent', model);
  }

  deleteFirmForEvent(id: number) {
    return this.http.post(this.url + '/deleteFirmForEvent', id);
  }

  releaseTicket(model: any) {
    return this.http.post(this.url + '/releaseticket', model);
  }

  publishPrices(model: any) {
    return this.http.post(this.url + '/publishprices', model);
  }

  generateTickets(model: any) {
    return this.http.post(this.url + '/generatetickets', model);
  }

  getTicketsDataForEvent(id: number) {
    return this.http.get(this.url + '/' + id + '/tickets');
  }

  validateEvent(id: number) {
    return this.http.get(this.url + '/' + id + '/validate');
  }

  activateEvent(id: number) {
    return this.http.post(this.url + '/activate', { id: id });
  }

  deactivateEvent(id: number) {
    return this.http.post(this.url + '/deactivate', { id: id });
  }

  cloneEvent(model: any) {
    return this.http.post(this.url + '/cloneEvent', model);
  }

  getEventsWithSameObjectMap(objectmapid: number) {
    return this.http.get(this.url + '/' + objectmapid + '/getEventsWithSameObjectMap');
  }

  saveCloneSeatsStatus(model) {
    return this.http.post(this.url + '/savecloneseatsstatus', model);
  }

  getEventLinks(id: number) {
    return this.http.get(this.url + '/geteventlinks/' + id);
  }

  getEventLink(eventlinkid: number) {
    return this.http.get(this.url + '/geteventlink/' + eventlinkid);
  }

  deleteEventLinksForEvent(id: number) {
    return this.http.post(this.url + '/deleteeventlinksforevent', id);
  }

  saveEventLinksForEvent(model: any) {
    return this.http.post(this.url + '/saveeventlinksforevent', model);
  }

  getBundlesForEvent(eventid: number) {
    return this.http.get(this.url + '/' + eventid + '/getbundlesforevent');
  }

  removeResults(eventId: any) {
    return this.http.post(this.url + '/remove-results/' + eventId, {});
  }
}
