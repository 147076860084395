import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventGroupsService } from '../event-groups.service';
import { UtilsService } from '../../common/services/utils.service';

@Component({
  selector: 'app-event-group-details',
  templateUrl: './event-group-details.component.html',
  styleUrls: ['./event-group-details.component.less']
})
export class EventGroupDetailsComponent implements OnInit {
  groupID: number;
  group: any;

  constructor(
    private service: EventGroupsService,
    private route: ActivatedRoute,
    private utils: UtilsService) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.route.paramMap.subscribe(paramMap => {
      this.groupID = Number(paramMap.get('id'));
      if (!this.groupID) {
        return;
      }

      this.service.get(this.groupID)
        .subscribe(group => {
          this.group = group;
        });
    });
  }
}
