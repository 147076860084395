import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from 'src/app/common/dialog.service';
import { HttpErrorHandler } from 'src/app/common/httpErrorHandler';
import { UtilsService } from 'src/app/common/services/utils.service';
import { EventsService } from '../../events.service';

@Component({
  selector: 'app-event-clone',
  templateUrl: './event-clone.component.html',
  styleUrls: ['./event-clone.component.less']
})
export class EventCloneComponent implements OnInit {
  @Input() eventID: number;
  @Input() eventName: string;
  @Input() startDateStruct:Date;
  @Input() endDateStruct:Date;
  @Input() startTimeStruct:number;
  @Input() endTimeStruct:number;
  @Input() evStartInternetStruct:Date;
  @Input() evEndInternetStruct:Date;
  @Input() startInternetTimeStruct:number;
  @Input() endInternetTimeStruct:number;
  @Input() evStartSalesStruct:Date;
  @Input() evEndSalesStruct:Date;
  @Input() startSalesTimeStruct:number;
  @Input() endSalesTimeStruct:number;
  
  errors = [];
  validation: any = null;
  event: any = {
    eventId: 0,
    evEventStartDate: null,
    evEventEndDate: null,
    startTimeStruct:{
      hour: 12,
      minute: 0
    },
    endTimeStruct:{
      hour: 12,
      minute: 0
    },
    evStartInternetDate: null,
    evEndInternetDate: null,
    startInternetTimeStruct: {
      hour: 12,
      minute: 0
    },
    endInternetTimeStruct: {
      hour: 12,
      minute: 0
    },
    evStartSalesDate: null,
    evEndSalesDate: null,
    startSalesTimeStruct: {
      hour: 12,
      minute: 0
    },
    endSalesTimeStruct: {
      hour: 12,
      minute: 0
    },
  };
  constructor(
    public activeModal: NgbActiveModal,
    private utils: UtilsService, 
    private eventService: EventsService,
    private dialogService: DialogService,
    private router: Router) { }

  ngOnInit() {
    this.event.evEventStartDate = this.startDateStruct;
    this.event.evEventEndDate = this.endDateStruct;
    this.event.startTimeStruct = this.startTimeStruct;
    this.event.endTimeStruct = this.endTimeStruct;

    this.event.evStartInternetDate = this.evStartInternetStruct;
    this.event.evEndInternetDate = this.evEndInternetStruct;
    this.event.startInternetTimeStruct = this.startInternetTimeStruct;
    this.event.endInternetTimeStruct = this.endInternetTimeStruct;

    this.event.evStartSalesDate = this.evStartSalesStruct;
    this.event.evEndSalesDate = this.evEndSalesStruct;
    this.event.startSalesTimeStruct = this.startSalesTimeStruct;
    this.event.endSalesTimeStruct = this.endSalesTimeStruct;
  }
  saveCloneEvent(){
    this.event.eventId = this.eventID;
    this.eventService.cloneEvent(this.event)
    .subscribe(
      data => {
        this.dialogService.successMessage('Event successfully saved.');
        if (data['id']) {
          //this.validateEvent(data['id']);
          this.activeModal.close();
          this.router.navigateByUrl('/event/' + data['id']);
        } 
      },
      (error: any) => {
      this.errors = HttpErrorHandler.retrieveErrors(error);
    });
    
  }
  validateEvent(id: number) {
    this.eventService.validateEvent(id)
      .subscribe(response => {
        this.validation = response;
      });
  }
  closeModal() {
    this.activeModal.close();
  }
}
