import { Component, OnInit, Input } from '@angular/core';
import { UsersService } from '../users.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '../../common/dialog.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';
import { SaveUserComponent } from '../save-user/save-user.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less']
})
export class UsersComponent implements OnInit {
  data: any = { users: [] };
  filter: any = {
    page: 1,
    size: 10,
    term: null,
    firmId: null
  };

  constructor(
    private service: UsersService,
    private modalService: NgbModal,
    private dialogService: DialogService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.filter.firmId = paramMap.get('id');
    });

    this.load();
  }

  load() {
    this.service.getAll(this.filter)
      .subscribe(data => {
        this.data = data;
      });
  }

  search() {
    this.filter.page = 1;
    this.load();
  }

  clear() {
    this.filter.page = 1;
    this.filter.term = null;
    this.load();
  }

  edit(user) {
    const modalRef = this.modalService.open(SaveUserComponent);
    modalRef.componentInstance.firmId = this.filter.firmId;
    if (!!user) {
      modalRef.componentInstance.id = user.id;
    }

    modalRef.result.then(result => {
      if (!!result) {
        this.load();
      }
    });
  }

  toggleStatus(user) {
    this.dialogService.confirmDialog('Are you sure you want to change the status of this user?')
      .then(response => {
        if (!!response) {
          this.service.toggleStatus(user.id)
            .subscribe(data => {
              this.load();
              this.dialogService.successMessage('User status changed.');
            });
        }
      });
  }

}
