import { Component, OnInit } from '@angular/core';
import { EventGroupsService } from '../event-groups.service';
import { AddEventGroupComponent } from './add-event-group/add-event-group.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../../common/services/utils.service';

@Component({
  selector: 'app-event-groups',
  templateUrl: './event-groups.component.html',
  styleUrls: ['./event-groups.component.less']
})
export class EventGroupsComponent implements OnInit {
  data: any = {
    events: []
  };

  lookup: any = {
    states: []
  };

  filter: any = {
    page: 1,
    size: 11,
    term: null,
    state: null,
    category: 0
  };
  page: number;

  constructor(
    private service: EventGroupsService,
    private modalService: NgbModal,
    private utils: UtilsService) {
  }

  ngOnInit() {
    this.service.lookup()
    .subscribe(lookup => {
      this.lookup = lookup as any;
      this.filter.state = this.lookup.states[0].key;
      //this.filter.category = this.lookup.categories[0].id;
      this.load();
    });
  }

  load() {
    this.service.getAll(this.filter)
      .subscribe(response => {
        this.data = response as any;
      });
  }

  search() {
    this.filter.page = 1;
    this.load();
  }

  clear() {
    this.filter.page = 1;
    this.filter.term = null;
    this.load();
  }

  editEventGroup(id) {
    const modalRef = this.modalService.open(AddEventGroupComponent);
    modalRef.componentInstance.eventGorupId = id;
    modalRef.result.then(result => {
      if (!!result) {
        this.data = {};
        this.load();
      }
    });
  }
}
