import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CookieService } from 'ngx-cookie-service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BlockUIModule } from 'ng-block-ui';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './common/auth-guard.service';
import { AuthService } from './common/auth.service';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { DataService } from './common/data.service';
import { EventDetailsComponent } from './events/event-details/event-details.component';
import { EventFirmsComponent } from './events/event-firms/event-firms.component';
import { EventGroupDetailsComponent } from './events/event-group-details/event-group-details.component';
import { EventGroupsService } from './events/event-groups.service';
import { EventGroupsComponent } from './events/event-groups/event-groups.component';
import { AddShippingMethodComponent } from './events/event-shipping-methods/add-event-shipping-method/add-shipping-method.component';
import { EventShippingMethodsComponent } from './events/event-shipping-methods/event-shipping-methods.component';
import { EventPricesComponent } from './events/event-prices/event-prices.component';
import { SaveEventSeatPriceComponent } from './events/event-prices/save-event-seat-price/save-event-seat-price.component';

import { EventsService } from './events/events.service';
import { ShippingMethodsService } from './shipping-methods/shipping-methods.service';
import { LoginComponent } from './users/login/login.component';
import { UsersService } from './users/users.service';
import { SetEventRowPriceComponent } from './events/event-prices/set-event-row-price/set-event-row-price.component';
import { SetEventZonePriceComponent } from './events/event-prices/set-event-zone-price/set-event-zone-price.component';
import { SellPeriodsComponent } from './sell-periods/sell-periods/sell-periods.component';
import { ShippingMethodsComponent } from './shipping-methods/shipping-methods/shipping-methods.component';
import { ObjectsComponent } from './objects/objects/objects.component';
import { SetEventZoneStatusComponent } from './events/event-prices/set-event-zone-status/set-event-zone-status.component';
import { SetEventRowStatusComponent } from './events/event-prices/set-event-row-status/set-event-row-status.component';
import { SetEventSeatStatusComponent } from './events/event-prices/set-event-seat-status/set-event-seat-status.component';
import { ConfirmDialogComponent } from './common/confirm-dialog/confirm-dialog.component';
import { AddFirmComponent } from './events/event-firms/add-firm/add-firm.component';
import { AddOrEditShippingMethodComponent } from './shipping-methods/add-or-edit-shipping-method/add-or-edit-shipping-method.component';
import { CustomErrorHandler } from './common/errorHandler';
import { FieldErrorsComponent } from './common/field-errors/field-errors.component';
import { AddOrEditSellPeriodComponent } from './sell-periods/add-or-edit-sell-period/add-or-edit-sell-period.component';
import { ObjectDetailsComponent } from './objects/object-details/object-details.component';
import { ObjectMapsComponent } from './objects/object-details/object-maps/object-maps.component';
import { MerchantPeriodSalesComponent } from './reports/merchant-period-sales/merchant-period-sales.component';
import { ReportsComponent } from './reports/reports/reports.component';
import { EventSalesReportComponent } from './events/event-reports/event-sales-report/event-sales-report.component';
import { AddObjectMapComponent } from './objects/object-details/add-object-map/add-object-map.component';
import { FieldSuccessComponent } from './common/field-success/field-success.component';
import { ObjectMapZonesComponent } from './objects/object-details/object-map-zones/object-map-zones.component';
// tslint:disable-next-line:max-line-length
import { AddNewObjectMapZoneComponent } from './objects/object-details/object-map-zones/add-new-object-map-zone/add-new-object-map-zone.component';
import { AddNewRowComponent } from './objects/object-details/object-map-zones/add-new-row/add-new-row.component';
// tslint:disable-next-line:max-line-length
import { AddNewNotNumeratedRowComponent } from './objects/object-details/object-map-zones/add-new-not-numerated-row/add-new-not-numerated-row.component';
import { AddNewSeatComponent } from './objects/object-details/object-map-zones/add-new-seat/add-new-seat.component';
import { AddEventGroupComponent } from './events/event-groups/add-event-group/add-event-group.component';
import { EventSeatsStatusReportComponent } from './events/event-reports/event-seats-status-report/event-seats-status-report.component';
import { ZoneSeatStatusReportComponent } from './events/event-reports/zone-seat-status-report/zone-seat-status-report.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { FirmDetailsComponent } from './firms/firm-details/firm-details.component';
import { SaveFirmComponent } from './firms/save-firm/save-firm.component';
import { EventTicketsComponent } from './events/event-tickets/event-tickets.component';
import { MenuItemDirective } from './common/directives/menu-item.directive';
import { SaleStatisticsComponent } from './reports/sale-statistics/sale-statistics.component';
import { EventSaleStatisticsComponent } from './reports/event-sale-statistics/event-sale-statistics.component';
import { ObjectMapDetailsComponent } from './objects/object-details/object-map-details/object-map-details.component';
import { DecimalPipe } from '@angular/common';
import { UsersComponent } from './users/users/users.component';
import { SaveUserComponent } from './users/save-user/save-user.component';
import { VirtualEventsComponent } from './virtual-events/virtual-events.component';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { AddEventToVirtualEventComponent } from './virtual-events/add-event-to-virtual-event/add-event-to-virtual-event.component';
import { AddReaderToVirtualEventComponent } from './virtual-events/add-reader-to-virtual-event/add-reader-to-virtual-event.component';
import { SaveVirtualEventComponent } from './virtual-events/save-virtual-event/save-virtual-event.component';
import { UnauthorizedComponent } from './common/unauthorized/unauthorized.component';
import { CustomHttpInterceptor } from './common/services/custom-http-interceptor';
import { OrdersComponent } from './reports/orders/orders.component';
import { MomentModule } from 'ngx-moment';
import { EventCloneComponent } from './events/event-details/event-clone/event-clone.component';
import { EventBundlePriceComponent } from './event-bundle-price/event-bundle-price.component';
import { BundleDetailsComponent } from './event-bundle-price/bundle-details/bundle-details.component';
import { CloneSeatStatusComponent } from './clone-seat-status/clone-seat-status.component';
import { EventLinksComponent } from './events/event-links/event-links.component';
import { AddOrEditEventLinkComponent } from './events/event-links/add-or-edit-event-link/add-or-edit-event-link.component';
import { TicketEditorComponent } from './ticket-editor/ticket-editor.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule } from '@angular/material';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EventGroupsComponent,
    DashboardComponent,
    EventGroupDetailsComponent,
    EventDetailsComponent,
    EventFirmsComponent,
    EventShippingMethodsComponent,
    EventPricesComponent,
    AddShippingMethodComponent,
    SaveEventSeatPriceComponent,
    SetEventRowPriceComponent,
    SetEventZonePriceComponent,
    SellPeriodsComponent,
    ShippingMethodsComponent,
    ObjectsComponent,
    SetEventZoneStatusComponent,
    SetEventRowStatusComponent,
    SetEventSeatStatusComponent,
    ConfirmDialogComponent,
    AddFirmComponent,
    AddOrEditShippingMethodComponent,
    ObjectDetailsComponent,
    FieldErrorsComponent,
    AddOrEditSellPeriodComponent,
    ObjectMapsComponent,
    MerchantPeriodSalesComponent,
    ReportsComponent,
    AddObjectMapComponent,
    EventSalesReportComponent,
    FieldSuccessComponent,
    ObjectMapZonesComponent,
    AddNewObjectMapZoneComponent,
    AddNewRowComponent,
    AddNewNotNumeratedRowComponent,
    AddNewSeatComponent,
    AddEventGroupComponent,
    EventSeatsStatusReportComponent,
    ZoneSeatStatusReportComponent,
    ConfigurationComponent,
    FirmDetailsComponent,
    SaveFirmComponent,
    EventTicketsComponent,
    MenuItemDirective,
    SaleStatisticsComponent,
    EventSaleStatisticsComponent,
    ObjectMapDetailsComponent,
    UsersComponent,
    SaveUserComponent,
    VirtualEventsComponent,
    ChangePasswordComponent,
    AddEventToVirtualEventComponent,
    AddReaderToVirtualEventComponent,
    SaveVirtualEventComponent,
    UnauthorizedComponent,
    OrdersComponent,
    EventCloneComponent,
    EventBundlePriceComponent,
    BundleDetailsComponent,
    CloneSeatStatusComponent,
    EventLinksComponent,
    AddOrEditEventLinkComponent,
    TicketEditorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    EditorModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BlockUIModule.forRoot(),
    NgHttpLoaderModule,
    MomentModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule
  ],
  entryComponents: [
    SaveEventSeatPriceComponent,
    SetEventRowPriceComponent,
    SetEventZonePriceComponent,
    SetEventSeatStatusComponent,
    SetEventRowStatusComponent,
    SetEventZoneStatusComponent,
    AddOrEditSellPeriodComponent,
    ConfirmDialogComponent,
    AddOrEditShippingMethodComponent,
    AddShippingMethodComponent,
    AddFirmComponent,
    AddObjectMapComponent,
    AddNewObjectMapZoneComponent,
    AddNewRowComponent,
    AddNewNotNumeratedRowComponent,
    AddNewSeatComponent,
    AddEventGroupComponent,
    SaveFirmComponent,
    SaveUserComponent,
    UsersComponent,
    AddReaderToVirtualEventComponent,
    AddEventToVirtualEventComponent,
    SaveVirtualEventComponent,
    ChangePasswordComponent,
    EventCloneComponent,
    AddOrEditEventLinkComponent
  ],
  providers: [
    AuthGuard,
    AuthService,
    CookieService,
    DataService,
    UsersService,
    EventsService,
    EventGroupsService,
    ShippingMethodsService,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
