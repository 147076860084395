import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DataService } from '../common/data.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends DataService {

  public url = 'api/reports';

  constructor(http: HttpClient) {
    super(http);
    // , 'api/reports'
  }

  merchantSalesForPeriodLookup() {
    return this.http.get(this.url + '/merchantsalesforperiodlookup');
  }

  getMerchantSalesForPeriod(model) {
    return this.http.post(this.url + '/merchantsalesforperiod', model);
  }

  getEventSales(model) {
    return this.http.post(this.url + '/eventsales', model);
  }

  exportEventSales(model) {
    return super.downloadDoc(this.url + '/eventsalesexport', model);
  }

  getEventSeatStatsByStatus(model) {
    return this.http.post(this.url + '/eventseatstatus', model);
  }

  getZoneSeatStatsByStatus(model) {
    return this.http.post(this.url + '/zoneseatstatus', model);
  }

  generateReportForMershantSales(model) {
    return this.http.post(this.url + '/generatereportformershantsales', model, {
      responseType: 'blob',
    });
  }

  saleStatistics(model) {
    return this.http.post(this.url + '/salestatistics', model);
  }

  eventSaleStatistics(model) {
    return this.http.post(this.url + '/eventsalestatistics', model);
  }

  saleStatisticsLookup() {
    return this.http.get(this.url + '/salestatisticslookup');
  }

  getOrders(model) {
    return this.http.post(this.url + '/orders', model);
  }
  structZoneSeatStatsByStatus(model) {
    return this.http.post(this.url + '/structzoneseatstatsbystatus', model);
  }
}
