import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from '../events.service';
import { combineLatest } from 'rxjs';
import { FilePair } from '../../common/data.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from '../../common/dialog.service';
import { UtilsService } from '../../common/services/utils.service';
import { NotificationsService } from '../../common/notifications.service';
import { Constant } from '../../common/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventCloneComponent } from './event-clone/event-clone.component';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class EventDetailsComponent implements OnInit {
  lookup: any = {
  };

  ticketData: any = null;
  eventID: number;
  eventName: string;
  event: any = {
    id: 0,
    objectMap: {},
    startDateStruct: null,
    endDateStruct: null,
    startTimeStruct: {
      hour: 12,
      minute: 0
    },
    endTimeStruct: {
      hour: 12,
      minute: 0
    },
    object: 0,
    evStartInternetStruct: null,
    evEndInternetStruct: null,
    startInternetTimeStruct: {
      hour: 12,
      minute: 0
    },
    endInternetTimeStruct: {
      hour: 12,
      minute: 0
    },
    evStartSalesStruct: null,
    evEndSalesStruct: null,
    startSalesTimeStruct: {
      hour: 12,
      minute: 0
    },
    endSalesTimeStruct: {
      hour: 12,
      minute: 0
    },
    resultsDisclaimer: null
  };
  imageFile: File;
  imageFileURL: any;
  thumbnailFile: File;
  thumbnailFileURL: any;
  prinPdfOnlineSeller: true;
  ticketFile: File;
  ticketFileURL: any;
  selection: any = {
    object: null
  };
  pdfFile: File;
  errors: any[] = [];
  validation: any = null;
  bundles: any = null;

  constructor(
    private service: EventsService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private utils: UtilsService,
    private notificationService: NotificationsService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.init();
    this.load();
  }

  init() {
    this.event.startDateStruct = this.utils.dateToStructure(new Date());
    this.event.endDateStruct = this.utils.dateToStructure(new Date());

    this.event.evStartInternetStruct = this.utils.dateToStructure(new Date());
    this.event.evEndInternetStruct = this.utils.dateToStructure(new Date());

    this.event.evStartSalesStruct = this.utils.dateToStructure(new Date());
    this.event.evEndSalesStruct = this.utils.dateToStructure(new Date());
  }

  load() {
    this.service.lookup()
      .subscribe(lookup => {
        this.lookup = lookup;
        this.setup();
        window.scrollTo(0, 0);
      });

    const params = this.route.paramMap;
    const queryParams = this.route.queryParamMap;
    const navigation = combineLatest([params, queryParams]);

    navigation.subscribe(paramMap => {
      this.eventID = Number(paramMap[0].get('id'));
      if (!this.eventID) {
        this.event.eventGroupId = Number(paramMap[1].get('groupid'));
        return;
      }

      this.service.get(this.eventID)
        .subscribe(
          event => {
            this.event = event;
            this.setup();
          }, error => {
            this.errors = HttpErrorHandler.retrieveErrors(error);
          });

      this.notificationService.notify.addListener(Constant.TICKETS_GENERATED_NOTIFICATION, () => {
        console.log('listener fired: tickets generated');
        this.validateEvent();
      });

      this.validateEvent();
      this.getBundlesForEvent(this.eventID);
    });
  }

  validateEvent() {
    this.service.validateEvent(this.eventID)
      .subscribe(response => {
        this.validation = response;
      });
  }

  setup() {
    if (!this.lookup ||
      !this.lookup.objects ||
      !this.event ||
      !this.event.objectMap ||
      !this.event.objectMap.object) {
      return;
    }

    this.lookup.objects.forEach((itm: any, ndx: number) => {
      if (itm.id === this.event.objectMap.object.id) {
        this.selection.object = itm;
        return;
      }
    });
  }

  imageUploaded(files: FileList) {
    this.errors = [];
    if (!files || !files.length) {
      return;
    }

    const file = files[0];
    const errors = this.utils.validateFile(file);
    if (!!errors.length) {
      this.errors.push({ key: 'Image', errors: errors });
      return;
    }

    this.imageFile = file;
    this.imageFileURL = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.imageFile));
  }

  thumbnailUploaded(files: FileList) {
    this.errors = [];
    if (!files || !files.length) {
      return;
    }

    const file = files[0];
    const errors = this.utils.validateFile(file);
    if (!!errors.length) {
      this.errors.push({ key: 'Thumbnail', errors: errors });
      return;
    }

    this.thumbnailFile = file;
    this.thumbnailFileURL = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.thumbnailFile));
  }

  ticketUploaded(files: FileList) {
    this.errors = [];
    if (!files || !files.length) {
      return;
    }

    const file = files[0];
    const errors = this.utils.validateFile(file);
    if (!!errors.length) {
      this.errors.push({ key: 'Ticket', errors: errors });
      return;
    }

    this.ticketFile = file;
    this.ticketFileURL = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.ticketFile));
  }

  save() {
    const files = [];
    if (!!this.imageFile) {
      files.push(new FilePair('image', this.imageFile));
    }

    if (!!this.thumbnailFile) {
      files.push(new FilePair('thumbnail', this.thumbnailFile));
    }
    if (!!this.ticketFile) {
      files.push(new FilePair('ticket', this.ticketFile));
    }
    if (!!this.pdfFile) {
      files.push(new FilePair('pdffile', this.pdfFile));
    }

    this.event.object = this.selection.object;
    this.errors = [];
    this.service.saveEvent(this.event, files)
      .subscribe(
        data => {
          this.dialogService.successMessage('Event successfully saved.');
          if (!this.eventID) {
            this.router.navigateByUrl('/event/' + data['id']);
          } else {
            this.pdfFile = null;
            this.load();
          }
        },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
          window.scrollTo(0, 0);
        });
  }

  resetObjectMap() {
    this.event.objectMap = {};
  }

  goBack() {
    this.router.navigateByUrl('/event-group/' + this.event.eventGroupId);
  }

  activate() {
    if (!this.eventID) {
      return;
    }

    this.dialogService.confirmDialog('Are you sure you want to activate this event?')
      .then(res => {
        if (!!res) {
          this.service.activateEvent(this.eventID)
            .subscribe(response => {
              this.load();
            });
        }
      });
  }

  deactivate() {
    if (!this.eventID) {
      return;
    }

    this.dialogService.confirmDialog('Are you sure you want to deactivate this event?')
      .then(res => {
        if (!!res) {
          this.service.deactivateEvent(this.eventID)
            .subscribe(response => {
              this.load();
            });
        }
      });
  }
  cloneEvent() {
    if (!this.eventID) {
      return;
    }

    const modalRef = this.modalService.open(EventCloneComponent);
    modalRef.componentInstance.eventID = this.eventID;
    modalRef.componentInstance.eventName = this.event.nameFirst;
    modalRef.componentInstance.startDateStruct = this.event.startDateStruct;
    modalRef.componentInstance.endDateStruct = this.event.endDateStruct;
    modalRef.componentInstance.startTimeStruct = this.event.startTimeStruct;
    modalRef.componentInstance.endTimeStruct = this.event.endTimeStruct;
    modalRef.componentInstance.evStartInternetStruct = this.event.evStartInternetStruct;
    modalRef.componentInstance.evEndInternetStruct = this.event.evEndInternetStruct;
    modalRef.componentInstance.startInternetTimeStruct = this.event.startInternetTimeStruct;
    modalRef.componentInstance.endInternetTimeStruct = this.event.endInternetTimeStruct;
    modalRef.componentInstance.evStartSalesStruct = this.event.evStartSalesStruct;
    modalRef.componentInstance.evEndSalesStruct = this.event.evEndSalesStruct;
    modalRef.componentInstance.startSalesTimeStruct = this.event.startSalesTimeStruct;
    modalRef.componentInstance.endSalesTimeStruct = this.event.endSalesTimeStruct;
  }

  pdfUploaded(files: FileList) {
    this.errors = [];
    if (!files || !files.length) {
      return;
    }

    const file = files[0];
    const errors = this.utils.validatePdfFile(file);
    if (!!errors.length) {
      this.errors.push({ key: 'PdfFile', errors: errors });
      return;
    }

    this.pdfFile = file;
  }

  getBundlesForEvent(eventId: number) {
    this.service.getBundlesForEvent(eventId)
      .subscribe(
        response => {
          this.bundles = response;
        });
  }

  deletePDF() {
    this.service.removeResults(this.event.id)
      .subscribe(res => {
        this.pdfFile = null;
        this.load();
      });

  }
}
