import { Component, OnInit } from '@angular/core';
import { AuthService } from './common/auth.service';
import { UsersService } from './users/users.service';
import { Router } from '@angular/router';
import { NotificationsService } from './common/notifications.service';
import { Constant } from './common/constants';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  user: any;
  on: boolean;

  constructor(
    private router: Router,
    private service: AuthService,
    private modalService: NgbModal,
    private userService: UsersService,
    private notificationService: NotificationsService) {
    this.notificationService.notify.addListener(Constant.LOGIN_NOTIFICATION, () => {
      this.user = this.service.getCurrentUser();
    });

    this.notificationService.notify.addListener(Constant.LOGOUT_NOTIFICATION, () => {
      this.user = null;
    });

    this.on = false;
  }

  ngOnInit() {
    this.user = this.service.getCurrentUser();
  }

  changePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent);
    modalRef.result.then(result => {

    });
  }

  logout() {
    this.userService.logout()
      .subscribe(success => {
        if (!!success) {
          this.notificationService.notify.emit(Constant.LOGOUT_NOTIFICATION);
          this.router.navigate(['login']);
        }
      });
  }
}
