import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorHandler } from '../../../common/httpErrorHandler';
import { EventGroupsService } from '../../event-groups.service';

@Component({
  selector: 'app-add-event-group',
  templateUrl: './add-event-group.component.html',
  styleUrls: ['./add-event-group.component.less']
})
export class AddEventGroupComponent implements OnInit {
  @Input() eventGorupId;
  constructor(
    private service: EventGroupsService,
    public activeModal: NgbActiveModal
  ) { }
  model: any = {
    id: 0,
    descriptionFirst: null,
    descriptionSecond: null,
    descriptionThird: null,
    nameFirst: null,
    nameSecond: null,
    nameThird: null,
    type: 0,
    isActive: true,
    priority: null,
    personal: false,
    mobileEnabled: false,
    parentId: 0
  };

 
  data: any = {};
  selection: any = {
    type: null,
    priority: null
  };
  records:any;
  errors: any[] = [];
  ngOnInit() {
    this.getPrioritiesAndTypes();
    if (this.eventGorupId !== 0) {
      this.getEventGroupById();
    }
      this.getEventGroupsParents();
    
  }
  getPrioritiesAndTypes() {
    this.service.getPrioritiesAndTypes().subscribe((data: any) => {
      this.data = data;
    }, (error: any) => {
      this.errors = HttpErrorHandler.retrieveErrors(error);
    });
  }
  getEventGroupsParents() {
    this.service.getEventGroupsParents()
    .subscribe((data: any) => {
      this.records = data;
    }, (error: any) => {
      this.errors = HttpErrorHandler.retrieveErrors(error);
    });
  }
  getEventGroupById() {
    this.service.get(this.eventGorupId).subscribe((data: any) => {
      this.model = data;
         }, (error: any) => {
      this.errors = HttpErrorHandler.retrieveErrors(error);
    });
  }
  closeModal() {
    this.activeModal.close();
  }

  saveEventGroup() {
    this.service.post(this.model).subscribe((data: any) => {
      this.activeModal.close(true);
    }, (error: any) => {
      this.errors = HttpErrorHandler.retrieveErrors(error);
    });
  }

}
