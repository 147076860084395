import { Component, OnInit, Input } from '@angular/core';
import { SellPeriodsService } from '../sell-periods.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-or-edit-sell-period',
  templateUrl: './add-or-edit-sell-period.component.html',
  styleUrls: ['./add-or-edit-sell-period.component.less']
})
export class AddOrEditSellPeriodComponent implements OnInit {
  @Input() id;

  model: any = {
    id: 0,
    nameFirst: null,
    nameSecond: null,
    nameThird: null,
    startDateStruct: {
      day: new Date().getDate(),
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1
    },
    endDateStruct: {
      day: new Date().getDate(),
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1
    }
  };
  errors: any[] = [];

  constructor(public activeModal: NgbActiveModal,
    private service: SellPeriodsService,
  ) { }

  ngOnInit() {
    if (this.id !== 0) {
      this.getItemById(this.id);
    }
  }

  getItemById(id: number) {
    this.service.get(this.id)
      .subscribe(method => {
        this.model = method;
      });
  }

  saveSellPeriod() {
    this.errors = [];
    this.service.saveSellPeriod(this.model)
      .subscribe(methods => {
        this.activeModal.close(true);
      },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
  }

  closeDialog() {
    this.activeModal.close();
  }
}
