import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from '../../events.service';
import { HttpErrorHandler } from '../../../common/httpErrorHandler';

@Component({
  selector: 'app-save-event-seat-price',
  templateUrl: './save-event-seat-price.component.html',
  styleUrls: ['./save-event-seat-price.component.less']
})
export class SaveEventSeatPriceComponent implements OnInit {
  @Input() eventid: number;
  @Input() seat: any;
  @Input() period: any;
  @Input() zone: any;
  @Input() row: any;

  errors = [];
  model: any = {
    eventId: null,
    seatId: null,
    zone: null,
    row: null,
    sellPeriodId: null,
    priceFirst: null,
    priceSecond: null
  };

  constructor(public activeModal: NgbActiveModal, private service: EventsService) { }

  ngOnInit() {
    this.model.eventId = this.eventid;
    this.model.zone = this.zone.id;
    this.model.row = this.row.row;
    this.model.seatId = this.seat.seat.id;
    this.model.sellPeriodId = this.period.id;
    this.model.priceFirst = this.seat.priceFirst;
    this.model.priceSecond = this.seat.priceSecond;
  }

  save() {
    this.errors = [];
    this.service.setSeatPrice(this.model)
      .subscribe(result => {
        this.activeModal.close(true);
      }, error => {
        this.errors = HttpErrorHandler.retrieveErrors(error);
      });
  }

}
