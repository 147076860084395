import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectsService } from '../../../objects.service';
import { HttpErrorHandler } from '../../../../common/httpErrorHandler';

@Component({
  selector: 'app-add-new-object-map-zone',
  templateUrl: './add-new-object-map-zone.component.html',
  styleUrls: ['./add-new-object-map-zone.component.less']
})
export class AddNewObjectMapZoneComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private service: ObjectsService,
  ) { }

  @Input() objectMapId;
  @Input() objectMapZoneId;
  @Input() editObjectMapZone;
  model: any = {
    id: 0,
    languageFirst: null,
    languageSecond: null,
    languageThird: null,
    numberOfSeats: 0,
    numberOfRows: 0,
    startAt: 1,
    isNumerated: false,
    editObjectMapZone: false
  };
  errors: any[] = [];

  ngOnInit() {
    if (!!this.objectMapZoneId) {
      this.getObjectMapZoneById();
    }
  }
  saveObjectMapZone() {
    this.model.objectId = this.objectMapId;
    this.model.editObjectMapZone = this.editObjectMapZone;
    this.service.saveObjectMapZone(this.model)
      .subscribe(methods => {
        this.activeModal.close(true);
      },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
  }
  closeModal() {
    this.activeModal.close();
  }
  getObjectMapZoneById() {
    this.service.getObjectMapZoneById(this.objectMapZoneId)
      .subscribe(zone => {
        this.model = zone;
      });
  }
}
