import { Component, OnInit, Input } from '@angular/core';
import * as shape from 'd3-shape';
import { ReportsService } from '../reports.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-event-sale-statistics',
  templateUrl: './event-sale-statistics.component.html',
  styleUrls: ['./event-sale-statistics.component.less']
})
export class EventSaleStatisticsComponent implements OnInit {
  @Input('filter') filter: any;
  loaded = false;
  data: any = {
    periodStatistics: [],
    zoneStatistics: [],
    priceBrekdownStatistics: [],
    ticketsStatistics: []
  };

  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  curve = shape.curveCardinal;

  colorScheme = {
    domain: ['#a3a0fb', '#ffda82', '#fe8373', '#54d8fe', '#993399', '#FF0000', '#18B265', '#0E55A0']
  };

  constructor(private service: ReportsService) { }

  ngOnInit() {
    this.load();
  }

  public load() {
    if (!this.filter.event) {
      return;
    }

    this.service.eventSaleStatistics(this.filter)
      .subscribe(data => {
        this.data = data;
        this.loaded = true;
      });
  }

}
