import { Injectable } from '@angular/core';
import { DataService } from '../common/data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VirtualEventsService extends DataService {

  public url= 'api/virtualevents';
  constructor(http: HttpClient) {
    super(http);
    //, 'api/virtualevents'
  }

  getAllReaders(model: any) {
    return super.getWithParams(this.url + '/getallreaders', model);
  }

  saveReadersForVirtualEvent(model) {
    return this.http.post(this.url + '/savereadersforvirtualevent', model);
  }

  saveEventsForVirtualEvents(model) {
    return this.http.post(this.url + '/saveEventsForVirtualEvents', model);
  }

  saveReader(model) {
    return this.http.post(this.url + '/savereader', model);
  }

}
