import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '../common/dialog.service';
import { EventGroupsService } from '../events/event-groups.service';
import { EventsService } from '../events/events.service';
import { ReportsService } from '../reports/reports.service';
import { SellPeriodsService } from '../sell-periods/sell-periods.service';
import { CloneSeatsStatus } from './clone-seats-status.model';
import { HttpErrorHandler } from 'src/app/common/httpErrorHandler';

@Component({
  selector: 'app-clone-seat-status',
  templateUrl: './clone-seat-status.component.html',
  styleUrls: ['./clone-seat-status.component.less']
})

export class CloneSeatStatusComponent implements OnInit {
  selection: any = [];
  selectionTo: any = [];
  data: any = {
    events: []
  };
  record: any = [];
  recordsTo: any = [];
  dataTo: any = []
  filter: any = {
    page: 1,
    size: 10,
    term: null,
    state: 2
  };
  fromEventGroup: any;
  toEventGroup: any;
  fromEvent: any;
  toEvent: any;
  public cloneSeatStatusForm: FormGroup;
  objectmapid: number;
  model: any = {
    eventId: null,
    zone: null
  };
  modelTo: any = {
    eventId: null
  };
  zones: any = [];
  periods: any = [];
  zone: any = [];
  statuses: any = [];
  errors: any[] = [];

  modelStatus: any = {
    eventId: null,
    zoneId: null,
    statusFrom: '',
    statusTo: ''
  };
  totalRowsAffected: any = [];

  constructor(private fb: FormBuilder,
    private eventGroupsService: EventGroupsService,
    private reportsService: ReportsService,
    private eventService: EventsService,
    private sellPeriodService: SellPeriodsService,
    private dialogService: DialogService) {

    this.cloneSeatStatusForm = this.fb.group({
      'fromEventGroup': new FormControl(this.fromEventGroup, Validators.required),
      'toEventGroup': new FormControl(this.toEventGroup, Validators.required),
      'fromEvent': new FormControl(this.fromEvent, Validators.required),
      'zone': new FormControl(this.zone, Validators.required),
      'statusFrom': new FormControl(this.statuses, Validators.required),
      'statusTo': new FormControl(this.statuses, Validators.required),
      'toEvent': new FormControl(this.toEvent, Validators.required),
    });
    this.selection.push(
      {
        eventGroup: null,
        event: null
      });
    this.selectionTo.push(
      {
        toEvent: null
      });
  }

  ngOnInit() {
    this.eventGroupsService.getAll(this.filter)
      .subscribe(response => {
        this.data = response as any;
      });
  }

  getEventSeatStatsZone() {
    this.model.eventId = this.selection.event;
    this.model.zone = this.selection.zone;
    this.reportsService.structZoneSeatStatsByStatus(this.model)
      .subscribe(data => {
        this.record = data[0];
      });
  }
  getEventSeatStatsZoneTo() {
    this.modelTo.eventId = this.selectionTo.toEvent;
    this.modelTo.zone = this.selection.zone;
    this.reportsService.structZoneSeatStatsByStatus(this.modelTo)
      .subscribe(data => {
        this.recordsTo = data[0];
      });
  }
  fillEventWithZones(eventId: number) {
    this.sellPeriodService.getAll({ page: 1, size: 1 })
      .subscribe(periods => {
        this.periods = periods['records'] || [];
        if (!this.periods || !this.periods.length) {
          return;
        }
        this.eventService.getEventZones(eventId, this.periods[0].id)
          .subscribe((zones: any[]) => {
            this.zones = zones;
            this.eventService.getSeatStatuses()
              .subscribe((allStatuses: any[]) => {
                this.statuses = [];
                allStatuses.forEach(s => {
                  if (s.key == 0 || s.key == 3 || s.key == 4) {
                    this.statuses.push({
                      key: s.key,
                      value: s.value
                    });
                  }
                });
              });
          });
      });

  }
  getEventsWithSameObjMap() {
    this.model.eventId = this.selection.event;
    this.selection.zone = 0
    this.fillEventWithZones(this.model.eventId);
    this.eventService.getEventsWithSameObjectMap(this.model.eventId)
      .subscribe(data => {
        this.dataTo = data;
      })
  }
  resetEvents() {
    this.selection.event = null;
  }
  resetToEvents() {
    this.selectionTo.toEvent = null;
  }
  save() {
    const newCloneSeatsStatus = new CloneSeatsStatus(
      this.cloneSeatStatusForm.value['fromEvent'],
      this.cloneSeatStatusForm.value['zone'],
      this.cloneSeatStatusForm.value['toEvent'],
      this.cloneSeatStatusForm.value['statusTo'],
      this.cloneSeatStatusForm.value['statusFrom'],
    );

    this.eventService.saveCloneSeatsStatus(newCloneSeatsStatus)
      .subscribe(
        (model: any[]) => {
          this.totalRowsAffected = model;
          this.dialogService.successMessage('Succesfully cloned ' + this.totalRowsAffected.length + 'seats.');
          window.scrollTo(0, 0);
        },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
  }
}
