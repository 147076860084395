import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.less']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() message;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  cancel() {
    this.activeModal.close();
  }

  save() {
    this.activeModal.close(true);
  }
}
