export class CloneSeatsStatus{
    public eventIdFrom: number;
    public zoneIdFrom: number;
    public statusFrom: number;
    public eventIdTo: number;
    public statusTo: number;
    

    constructor(eventIdFrom: number, zoneIdFrom: number, eventIdTo: number, statusTo:number,statusFrom?: number){
        this.eventIdFrom=eventIdFrom;
        this.zoneIdFrom = zoneIdFrom;
        this.statusFrom = statusFrom;
        this.eventIdTo =  eventIdTo;
        this.statusTo = statusTo;
    }
}