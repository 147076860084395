import { Injectable } from '@angular/core';
import { DataService } from '../common/data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FirmService extends DataService {

  public url='api/firms';
  constructor(http: HttpClient) {
    super(http);
    //, 'api/firms'
  }
  getAllFirms(model: any) {
    return this.http.post(this.url + '/getallfirms', model);
  }

  getAllCities() {
    return this.http.get(this.url + '/getallCities');
  }

  getFirmById(firmid) {
    return this.http.get(this.url + '/' + firmid + '/getfirmbyid');
  }

  saveFirm(model) {
    return this.http.post(this.url + '/savefirm', model);
  }

  deleteFirm(firmid) {
    return this.http.get(this.url + '/' + firmid + '/deletefirm');
  }
}
