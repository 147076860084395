import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../../../reports/reports.service';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '../../../common/dialog.service';
import { EventsService } from '../../events.service';

@Component({
  selector: 'app-event-sales-report',
  templateUrl: './event-sales-report.component.html',
  styleUrls: ['./event-sales-report.component.less']
})
export class EventSalesReportComponent implements OnInit {
  data: any = { records: [] };
  paymentMethods: any[] = [
    { id: 2, name: 'Regular tickets' },
    { id: 7, name: 'Extra tickets' }
  ];
  paymentStatuses: any[] = [
    { id: 0, name: 'Pending' },
    { id: 1, name: 'Reserved' },
    { id: 2, name: 'Paid'},
    { id: -1, name: 'Canceled' },
    { id: -2, name: 'Failed' },
    { id: -3, name: 'Chargeback'},
  ];
  model: any = {
    eventId: null,
    paymentMethod: 2,
    page: 1,
    size: 10,
    term: '',
    paymentStatus: 2
  };
  errors: [null];
  constructor(
    private service: ReportsService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private eventService: EventsService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.model.eventId = Number(paramMap.get('id'));
      if (!this.model.eventId) {
        return;
      }

      this.load();
    });
  }

  load() {
    this.service.getEventSales(this.model)
      .subscribe(data => {
        this.data = data;
      });
  }

  export() {
    this.service.exportEventSales(this.model);
  }

  releaseSeat(item) {
    this.dialogService.confirmDialog('Are you sure you want to release this event seat?')
      .then(response => {
        if (!!response) {
          this.eventService.releaseTicket({ ticketNumber: item.ticketNumber })
            .subscribe(data => {
              this.dialogService.infoMessage('The seat has been released!');
              this.load();
            });
        }
      });
  }

}
