import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../../events.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-add-firm',
  templateUrl: './add-firm.component.html',
  styleUrls: ['./add-firm.component.less']
})

export class AddFirmComponent implements OnInit {
  @Input() eventid;

  firms: any[];
  model: any = {
    id: 0,
    selectedFirmIds: new Array<any>()
  };

  constructor(
    private service: EventsService,
    private route: ActivatedRoute,
    public activeModal: NgbActiveModal) {
    this.model.shippingMethodIds = new Array<any>();
  }

  ngOnInit() {
    this.load();
  }
  load() {
    this.service.getFirmsForEvent(this.eventid)
      .subscribe(response => {
        this.firms = response as any[];
        if (this.firms.length > 0) {
          for (const method of this.firms) {
            if (method.isInEvent) {
              const firmModel = {
                Id: 0,
                Enabled: true
              };
              firmModel.Id = method.id;
              firmModel.Enabled = method.enabled;
              method.isInEvent = true;
              this.model.selectedFirmIds.push(firmModel);
            }
          }
        }
      });
  }

  selectFirm(item: any) {
    if (item.isInEvent) {
      const index = this.model.selectedFirmIds.findIndex(x => x.Id === item.id);
      if (index !== -1) {
        this.model.selectedFirmIds.splice(index, 1);
        item.isInEvent = false;
      }
    } else {
      const firmModel = {
        Id: 0,
        Enabled: true
      };
      firmModel.Id = item.id;
      firmModel.Enabled = item.enabled;
      item.isInEvent = true;
      this.model.selectedFirmIds.push(firmModel);
    }
  }
  closeModal() {
    this.activeModal.close();
  }

  saveFirmForEvent() {
    this.model.id = this.eventid;
    this.service.saveFirmsForEvent(this.model).subscribe((data: any) => {
      this.activeModal.close(true);
    }, (error: any) => {
      console.log(error);
    });
  }

  enableFirm(firm: any) {
    firm.enabled = firm.enabled ? false : true;
    const index = this.model.selectedFirmIds.findIndex(x => x.Id === firm.id);
    if (index !== -1) {
      this.model.selectedFirmIds[index].Enabled = firm.enabled;
    }
  }
}



