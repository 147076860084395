import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from '../../events.service';

@Component({
  selector: 'app-set-event-seat-status',
  templateUrl: './set-event-seat-status.component.html',
  styleUrls: ['./set-event-seat-status.component.less']
})
export class SetEventSeatStatusComponent implements OnInit {
  @Input() seat: any;
  @Input() row: any;
  @Input() zone: any;
  @Input() eventid: number;
  statuses: any = [];
  model: any = {
    eventId: null,
    seatId: null,
    status: ''
  };

  constructor(public activeModal: NgbActiveModal, private service: EventsService) { }

  ngOnInit() {
    this.model.eventId = this.eventid;
    this.model.seatId = this.seat.seat.id;
    this.load();
  }

  load() {
    this.service.getSeatStatuses()
      .subscribe(statuses => {
        this.statuses = statuses;
      });
  }

  save() {
    this.service.setSeatStatus(this.model)
      .subscribe(success => {
        if (!!success) {
          this.activeModal.close(true);
        }
      });
  }

}
