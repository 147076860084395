import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObjectsService } from '../../objects.service';

@Component({
  selector: 'app-object-map-details',
  templateUrl: './object-map-details.component.html',
  styleUrls: ['./object-map-details.component.less']
})
export class ObjectMapDetailsComponent implements OnInit {
  objectMapId: number;
  objectMap: any = {

  };
  constructor(private route: ActivatedRoute, private router: Router, private service: ObjectsService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.objectMapId = Number(paramMap.get('id'));
      if (!this.objectMapId) {
        this.route.queryParamMap.subscribe(queryMap => {
          this.objectMap.objectId = Number(queryMap.get('objectid'));
        });

        return;
      }

      this.service.getObjectMap(this.objectMapId)
        .subscribe(data => {
          this.objectMap = data;
        });
    });
  }

  goBack() {
    this.router.navigate(['/object', this.objectMap.objectId]);
  }

}
