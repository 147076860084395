import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from '../../events.service';
import { HttpErrorHandler } from '../../../common/httpErrorHandler';

@Component({
  selector: 'app-set-event-zone-price',
  templateUrl: './set-event-zone-price.component.html',
  styleUrls: ['./set-event-zone-price.component.less']
})
export class SetEventZonePriceComponent implements OnInit {
  @Input() eventid: number;
  @Input() period: any;
  @Input() zone: any;

  errors = [];
  model: any = {
    zoneId: null,
    eventId: null,
    priceFirst: null,
    priceSecond: null,
    sellPeriodId: null,
    range: false,
    rangeStart: null,
    rangeEnd: null
  };

  constructor(
    public activeModal: NgbActiveModal,
    private eventService: EventsService) {
  }

  ngOnInit() {
    this.model.sellPeriodId = this.period.id;
    this.model.eventId = this.eventid;
    this.model.zoneId = this.zone.id;
  }

  save() {
    this.errors = [];
    this.eventService.setZonePrice(this.model)
      .subscribe(result => {
        this.activeModal.close(true);
      }, error => {
        this.errors = HttpErrorHandler.retrieveErrors(error);
      });
  }
}
