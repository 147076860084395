import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!!this.authService.isAuthenticated()) {
      const features = route.data.features;
      if (!!features) {
        for (const feature of features) {
          if (!!this.authService.canUserAccessFeature(feature)) {
            return true;
          }
        }
      }

      this.router.navigate(['/unauthorized']);
      return false;
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
