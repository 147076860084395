import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VirtualEventsService } from '../virtual-events.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';

@Component({
  selector: 'app-save-virtual-event',
  templateUrl: './save-virtual-event.component.html',
  styleUrls: ['./save-virtual-event.component.less']
})
export class SaveVirtualEventComponent implements OnInit {
  @Input() virtualEvent: any;
  errors: any[] = [];
  controlModes: any[] = [
    { id: 1, name: 'Entrance only' },
    { id: 2, name: 'Entrance & exit' }
  ];
  model: any = {
    id: null,
    name: null,
    enableGateControl: false,
    enabled: false,
    controlMode: null
  };

  constructor(
    private modal: NgbActiveModal,
    private service: VirtualEventsService) { }

  ngOnInit() {
    this.load();
  }

  load() {
    if (!!this.virtualEvent) {
      this.model = this.virtualEvent;
    } else {
      this.model.controlMode = this.controlModes[0].id;
    }
  }

  save() {
    this.errors = [];
    this.service.post(this.model)
      .subscribe(response => {
        this.modal.close(true);
      }, error => {
        this.errors = HttpErrorHandler.retrieveErrors(error);
      });
  }

  close() {
    this.modal.close();
  }

}
