import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse, caught) => {
      if (error.status === 401) {
        this.router.navigate(['/login']);
        return of(error.message);
      }

      if (error.status === 403) {
        this.router.navigate(['/unauthorized']);
        return of(error.message);
      }

      return throwError(error);
    }) as any);
  }


}
