import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private modalService: NgbModal, private toastr: ToastrService) { }

  confirmDialog(message) {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.message = message;
    return modalRef.result;
  }

  infoMessage(message) {
    this.toastr.info(message);
  }

  errorMessage(message) {
    this.toastr.error(message);
  }

  successMessage(message) {
    this.toastr.success(message);
  }
}
