import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectsService } from '../../../objects.service';
import { HttpErrorHandler } from '../../../../common/httpErrorHandler';

@Component({
  selector: 'app-add-new-not-numerated-row',
  templateUrl: './add-new-not-numerated-row.component.html',
  styleUrls: ['./add-new-not-numerated-row.component.less']
})
export class AddNewNotNumeratedRowComponent implements OnInit {
  @Input() seatsCount;
  @Input() objectMapZone;

  model: any = {};
  seatsCountEdited: number;
  errors: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private service: ObjectsService,
  ) { }

  ngOnInit() {
    this.model = {
      zoneId : this.objectMapZone.id,
      seatNumber : this.seatsCount
    };
  }

  closeModal() {
    this.activeModal.close();
  }

  save () {
    this.service.addNewSeatsInNotNumeratedRow(this.model)
    .subscribe(methods => {
      this.activeModal.close(true);
    },
      error => {
        this.errors = HttpErrorHandler.retrieveErrors(error);
      });
  }
}
