import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service';
import { UtilsService } from '../../common/services/utils.service';

@Component({
  selector: 'app-merchant-period-sales',
  templateUrl: './merchant-period-sales.component.html',
  styleUrls: ['./merchant-period-sales.component.less']
})
export class MerchantPeriodSalesComponent implements OnInit {
  data: any = { details: [] };
  lookup: any = { groups: [], firms: [] };
  selection: any = { group: '' };
  model: any = {
    startDate: null,
    endDate: null,
    eventId: '',
    firmId: '',
    page: 1,
    size: 10
  };
  errors: any = [];

  constructor(
    private service: ReportsService,
    private utils: UtilsService) { }

  ngOnInit() {
    this.setup();
    this.load();
  }

  setup() {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    this.model.startDate = this.utils.dateToStructure(today);
    this.model.endDate = this.utils.dateToStructure(new Date());
  }

  load() {
    this.service.merchantSalesForPeriodLookup()
      .subscribe(data => {
        this.lookup = data;
        this.filter();
      });
  }

  filter() {
    this.service.getMerchantSalesForPeriod(this.model)
      .subscribe(details => {
        this.data = details;
      });
  }

  groupChanged() {
    if (!this.selection.group ||
      !this.selection.group.events ||
      !this.selection.group.events.length) {
      this.model.eventId = '';
      return;
    }

    this.model.eventId = this.selection.group.events[0].id;
  }

  getExportToExcelURL() {
    return this.service.url + '/merchantperiodsales?' +
      (!!this.model.eventId ? 'eventId=' + this.model.eventId : '') +
      (!!this.model.firmId ? '&firmId=' + this.model.firmId : '') +
      '&startDate=' + this.model.startDate.year +
      this.utils.padLeft(this.model.startDate.month, '0', 2) +
      this.utils.padLeft(this.model.startDate.day, '0', 2) +
      '&endDate=' + this.model.endDate.year +
      this.utils.padLeft(this.model.endDate.month, '0', 2) +
      this.utils.padLeft(this.model.endDate.day, '0', 2) +
      '&page=1&size=' + this.data.total;
  }


}
