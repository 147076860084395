import { retry } from 'rxjs/internal/operators';
import { Component, OnInit } from '@angular/core';
import { SellPeriodsService } from '../../sell-periods/sell-periods.service';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../events.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SaveEventSeatPriceComponent } from './save-event-seat-price/save-event-seat-price.component';
import { SetEventRowPriceComponent } from './set-event-row-price/set-event-row-price.component';
import { SetEventZonePriceComponent } from './set-event-zone-price/set-event-zone-price.component';
import { SetEventSeatStatusComponent } from './set-event-seat-status/set-event-seat-status.component';
import { SetEventRowStatusComponent } from './set-event-row-status/set-event-row-status.component';
import { SetEventZoneStatusComponent } from './set-event-zone-status/set-event-zone-status.component';
import { DialogService } from '../../common/dialog.service';
import { Constant } from '../../common/constants';
import { NotificationsService } from '../../common/notifications.service';

@Component({
  selector: 'app-event-prices',
  templateUrl: './event-prices.component.html',
  styleUrls: ['./event-prices.component.less']
})
export class EventPricesComponent implements OnInit {
  eventid: number;
  periods: any[] = [];
  zones: any[] = [];
  selection: any = {
    period: null
  };

  constructor(
    private sellPeriodService: SellPeriodsService,
    private eventService: EventsService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private notificationService: NotificationsService) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.sellPeriodService.getAll({ page: 1, size: 200 })
      .subscribe(periods => {
        this.periods = periods['records'] || [];
        if (!this.periods || !this.periods.length) {
          return;
        }

        this.route.paramMap
          .subscribe(paramMap => {
            this.eventid = Number(paramMap.get('id'));
            if (!this.eventid) {
              return;
            }

            this.selection.period = this.periods[0];
            this.setSelectedPeriod();
          });
      });
  }

  setSelectedPeriod() {
    this.loadZones();
  }

  loadZones() {
    if (!this.eventid || !this.selection.period) {
      return;
    }

    this.eventService.getEventZones(this.eventid, this.selection.period.id)
      .subscribe(zones => {
        this.zones = zones as any[];
      });
  }

  loadRows(zone: any, hard?: boolean) {
    if (!!zone.rows && !hard) {
      zone.opened = !zone.opened;
      return;
    }

    this.eventService.getRowsForZone(this.eventid, zone.id, this.selection.period.id)
      .subscribe(rows => {
        zone.rows = rows;
        zone.opened = true;
      });
  }

  loadSeats(zone: any, row: any, hard?: boolean) {
    if (!!row.seats && !hard) {
      row.opened = !row.opened;
      return;
    }

    this.eventService.getSeatsForRow(this.eventid, zone.id, row.row, this.selection.period.id)
      .subscribe(seats => {
        row.seats = seats;
        row.opened = true;
      });
  }

  setSeatPrice(zone: any, row: any, seat: any) {
    const modalRef = this.modalService.open(SaveEventSeatPriceComponent);
    modalRef.componentInstance.eventid = this.eventid;
    modalRef.componentInstance.zone = zone;
    modalRef.componentInstance.row = row;
    modalRef.componentInstance.seat = seat;
    modalRef.componentInstance.period = this.selection.period;
    modalRef.result.catch(x => { });
    modalRef.result.then(result => {
      if (!!result) {
        this.loadSeats(zone, row, true);
      }
    });
  }

  setRowPrice(zone: any, row: any) {
    const modalRef = this.modalService.open(SetEventRowPriceComponent);
    modalRef.componentInstance.period = this.selection.period;
    modalRef.componentInstance.eventid = this.eventid;
    modalRef.componentInstance.zone = zone;
    modalRef.componentInstance.row = row;
    modalRef.result.catch(x => { });
    modalRef.result.then(result => {
      if (!!result) {
        this.loadRows(zone, true);
      }
    });
  }

  setZonePrice(zone: any) {
    const modalRef = this.modalService.open(SetEventZonePriceComponent);
    modalRef.componentInstance.period = this.selection.period;
    modalRef.componentInstance.eventid = this.eventid;
    modalRef.componentInstance.zone = zone;
    modalRef.result.catch(x => { });
    modalRef.result.then(result => {
      if (!!result) {
        this.loadZones();
      }
    });
  }

  setSeatStatus(zone: any, row: any, seat: any) {
    const modalRef = this.modalService.open(SetEventSeatStatusComponent);
    modalRef.componentInstance.eventid = this.eventid;
    modalRef.componentInstance.zone = zone;
    modalRef.componentInstance.row = row;
    modalRef.componentInstance.seat = seat;
    modalRef.result.catch(x => { });
    modalRef.result.then(result => {
      if (!!result) {
        this.loadSeats(zone, row, true);
      }
    });
  }

  setRowStatus(zone: any, row: any, seat: any) {
    const modalRef = this.modalService.open(SetEventRowStatusComponent);
    modalRef.componentInstance.eventid = this.eventid;
    modalRef.componentInstance.zone = zone;
    modalRef.componentInstance.row = row;
    modalRef.result.catch(x => { });
    modalRef.result.then(result => {
      if (!!result) {
        this.loadRows(zone, true);
      }
    });
  }

  setZoneStatus(zone: any, row: any, seat: any) {
    const modalRef = this.modalService.open(SetEventZoneStatusComponent);
    modalRef.componentInstance.eventid = this.eventid;
    modalRef.componentInstance.zone = zone;
    modalRef.result.catch(x => { });
    modalRef.result.then(result => {
      if (!!result) {
        this.loadZones();
      }
    });
  }

  publishPrices() {
    if (!this.eventid || !this.selection.period || !this.selection.period.id) {
      return;
    }

    const model = {
      eventId: this.eventid,
      periodId: this.selection.period.id
    };

    this.eventService.publishPrices(model)
      .subscribe(
        data => {
          if (!!data) {
            this.loadZones();
            this.dialogService.successMessage('Prices successfully published!');
            this.notificationService.notify.emit(Constant.TICKETS_GENERATED_NOTIFICATION);
          }
        });
  }
}
