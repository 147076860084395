import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef } from '@angular/core';

export class HttpErrorHandler {

    static handleError(errorResponse: HttpErrorResponse, frm: ElementRef) {
        let generalErrors = [];
        if (errorResponse.status === 400) {
            if (!!frm.nativeElement.elements) {
                Object.keys(frm.nativeElement.elements).forEach(key => {
                    console.log(key);
                    const ctrl = frm.nativeElement.elements[key];
                    if (!ctrl) {
                        return;
                    }

                    console.log(ctrl);
                });
            }

            generalErrors = HttpErrorHandler.errorsForKey(errorResponse, '');
            if (!generalErrors) {
                return;
            }

            return generalErrors;
        }

        alert('Service not available');
        return [];
    }

    static retrieveErrors(errorResponse: HttpErrorResponse): any[] {
        let errors = [];
        if (errorResponse.status === 400) {
            const errorObj = errorResponse.error['obj'];
            if (!errorObj) {
                return errors;
            }

            const errorList = errorObj['errors'];
            if (!errorList) {
                return errors;
            }

            errors = errorList;
        } else {
            errors.push({ key: '', errors: ['Service not available'] });
        }

        return errors;
    }

    private static errorsForKey(errorResponse: HttpErrorResponse, key: string): any[] {
        const errorObj = errorResponse.error['obj'];
        if (!errorObj) {
            return;
        }

        const errorList = errorObj['errors'];
        if (!errorList) {
            return;
        }

        let errors = [];
        for (const entry of errorList) {
            if (entry['key'] !== key) {
                const errorMessages = entry['errors'] as string[];
                errors = errorMessages;
                break;
            }
        }

        return errors;
    }

    static retrieveErrorsAsSingleMessage(errorResponse: HttpErrorResponse) {
        const errors = HttpErrorHandler.retrieveErrors(errorResponse);
        if (!errors || !errors.length) {
            return;
        }

        const messages = [];
        errors.forEach((val, ndx) => {
            val.errors.forEach((err: string, i) => {
                messages.push(err);
            });
        });

        return messages.join(', ');
    }
}
