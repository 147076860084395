import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ObjectsService } from '../../../objects.service';
import { HttpErrorHandler } from '../../../../common/httpErrorHandler';

@Component({
  selector: 'app-add-new-seat',
  templateUrl: './add-new-seat.component.html',
  styleUrls: ['./add-new-seat.component.less']
})
export class AddNewSeatComponent implements OnInit {
  @Input() zoneId;
  @Input() rowNumber;
  seatNumber: number;
  errors: any[] = [];
  constructor(
    public activeModal: NgbActiveModal,
    private service: ObjectsService,
  ) { }

  ngOnInit() {
  }

  addNewSeatInRow() {
    this.service.addNewSeatInRow({ zoneId: this.zoneId, row: this.rowNumber, seatNumber: this.seatNumber })
      .subscribe(methods => {
        this.activeModal.close(methods);
      },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
  }

  closeModal() {
    this.activeModal.close();
  }
}
