import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObjectsService } from '../objects.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';
import { FilePair } from '../../common/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../../common/services/utils.service';
import { DialogService } from '../../common/dialog.service';

@Component({
  selector: 'app-object-details',
  templateUrl: './object-details.component.html',
  styleUrls: ['./object-details.component.less']
})
export class ObjectDetailsComponent implements OnInit {
  object: any = {
    id: 0,
    addressFirst: null,
    addressSecond: null,
    addressThird: null,
    descriptionFirst: null,
    descriptionSecond: null,
    descriptionThird: null,
    nameFirst: null,
    nameSecond: null,
    nameThird: null,
    image: null,
    longitude: null,
    latitude: null
  };
  objectid: number;
  errors: any[] = [];
  imageFile: File;
  imageFileURL: any;

  constructor(
    private route: ActivatedRoute,
    private service: ObjectsService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private utils: UtilsService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.route.paramMap.subscribe(paramMap => {
      this.objectid = Number(paramMap.get('id'));
      if (!this.objectid) {
        return;
      }

      this.service.get(this.objectid)
        .subscribe(model => {
          this.object = model;
        });
    });
  }

  save() {
    const files = [];
    if (!!this.imageFile) {
      files.push(new FilePair('image', this.imageFile));
      this.object.image = this.imageFile.name;
    }

    this.service.saveObject(this.object, files)
      .subscribe(
        model => {
          if (!this.objectid) {
            this.router.navigateByUrl('/object/' + model);
          }

          this.dialogService.successMessage('Object succesfully saved.');
          window.scrollTo(0, 0);
        },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
  }

  imageUploaded(files: FileList) {
    this.errors = [];
    if (!files || !files.length) {
      return;
    }

    const file = files[0];
    const errors = this.utils.validateFile(file);
    if (!!errors.length) {
      this.errors.push({ key: 'Image', errors: errors });
      return;
    }

    this.imageFile = file;
    this.imageFileURL = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.imageFile));
  }
}
