import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObjectsService } from '../../objects.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNewObjectMapZoneComponent } from './add-new-object-map-zone/add-new-object-map-zone.component';
import { AddNewRowComponent } from './add-new-row/add-new-row.component';
import { AddNewNotNumeratedRowComponent } from './add-new-not-numerated-row/add-new-not-numerated-row.component';
import { AddNewSeatComponent } from './add-new-seat/add-new-seat.component';
import { HttpErrorHandler } from '../../../common/httpErrorHandler';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from '../../../common/dialog.service';

@Component({
  selector: 'app-object-map-zones',
  templateUrl: './object-map-zones.component.html',
  styleUrls: ['./object-map-zones.component.less']
})
export class ObjectMapZonesComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private service: ObjectsService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private dialogService: DialogService
  ) { }

  objectMap: any = null;
  objectMapId: number;
  data: any = {
    records: []
  };

  model: any = {
    page: 1,
    size: 10,
    term: null,
    objectId: null,
  };

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.objectMapId = Number(paramMap.get('id'));
      if (!this.objectMapId) {
        return;
      }

      this.model.objectId = this.objectMapId;
      this.load();
    });
  }

  load() {
    this.service.getObjectMap(this.objectMapId)
      .subscribe(data => {
        this.objectMap = data;
        this.service.getObjectMapZone(this.model)
          .subscribe(mapZones => {
            this.data = mapZones;
          });
      });
  }

  takeSeatsForZone(mapZone, isEdited) {
    if (!isEdited) {
      if (!!mapZone.seats) {
        mapZone.opened = !mapZone.opened;
        return;
      }
    }

    mapZone.seats = [];
    this.service.getSeatsForZone(mapZone.id)
      .subscribe(mapseats => {
        mapZone.seats = mapseats as any[];
      });

    mapZone.opened = true;
  }

  showSeatsForRow(seat) {
    seat.opened = !seat.opened;
  }

  addNewMapZone() {
    if (!this.objectMapId) {
      return;
    }

    const modalRef = this.modalService.open(AddNewObjectMapZoneComponent);
    modalRef.componentInstance.objectMapId = this.objectMapId;
    modalRef.componentInstance.editObjectMapZone = false;
    modalRef.componentInstance.editObjectMapZone = 0;
    modalRef.result.then(result => {
      if (!!result) {
        this.data = [];
        this.load();
      }
    });
  }

  editObjectMapZone(mapzone) {
    if (!this.objectMapId) {
      return;
    }

    const modalRef = this.modalService.open(AddNewObjectMapZoneComponent);
    modalRef.componentInstance.objectMapId = this.objectMapId;
    modalRef.componentInstance.editObjectMapZone = true;
    modalRef.componentInstance.objectMapZoneId = mapzone.id;
    modalRef.result.then(result => {
      if (!!result) {
        this.data = [];
        this.load();
      }
    });
  }

  addNewRow(objectMapZone) {
    if (!!objectMapZone.isNumerated) {
      const modalRef = this.modalService.open(AddNewRowComponent);
      modalRef.componentInstance.objectMapZoneId = objectMapZone.id;
      modalRef.result.then(result => {
        if (!!result) {
          this.takeSeatsForZone(objectMapZone, true);
        }
      });
    } else {
      this.addNotNumeratedRow(objectMapZone);
    }
  }

  addNotNumeratedRow(objectMapZone: any) {
    this.service.getNotNumberedSeatsCount(objectMapZone.id)
      .subscribe(seatsCount => {
        const modalRef = this.modalService.open(AddNewNotNumeratedRowComponent);
        modalRef.componentInstance.seatsCount = seatsCount as number;
        modalRef.componentInstance.objectMapZone = objectMapZone;
        modalRef.result.then(result => {
          if (!!result) {
            this.data = [];
            this.load();
          }
        });
      });
  }

  deleteObjectMapZone(id) {
    this.dialogService.confirmDialog('Are you sure you want to delete the entire zone?')
      .then(result => {
        if (!!result) {
          this.service.deleteObjectMapZone(id)
            .subscribe(mapZones => {
              this.load();
            }, error => {
              this.toastr.error(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
            });
        }
      });
  }

  deleteSeat(seatId, row) {
    this.dialogService.confirmDialog('Are you sure you want to delete the seat?')
      .then(result => {
        if (!!result) {
          this.service.deleteObjectZoneSeat(seatId)
            .subscribe(seats => {
              row.listSeats = [];
              row.listSeats = seats;
              row.seatsInRow = row.listSeats.length;
            }, error => {
              this.toastr.error(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
            });
        }
      });
  }

  deleteRow(mapZone, zone) {
    this.dialogService.confirmDialog('Are you sure you want to delete the entire row?')
      .then(result => {
        if (!!result) {
          this.service.deleteRowForZone(mapZone.rowNumber, zone.id)
            .subscribe(mapZoneResponse => {
              zone.seats = mapZoneResponse as any[];
            }, error => {
              this.toastr.error(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
            });
        }
      });
  }

  addNewSeatForRow(row, zoneId) {
    const modalRef = this.modalService.open(AddNewSeatComponent);
    modalRef.componentInstance.zoneId = zoneId as number;
    modalRef.componentInstance.rowNumber = row.rowNumber as number;
    modalRef.result.then(result => {
      if (!!result) {
        row.listSeats = result;
        row.seatsInRow = row.listSeats.length;
      }
    });
  }
}
