import { Injectable } from '@angular/core';
import { DataService } from '../common/data.service';
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from 'events';

@Injectable()
export class UsersService extends DataService {
  public url ='api/users';
  
  constructor(client: HttpClient) {
    super(client);
    //, 'api/users'
  }

  login(model) {
    return this.http.post(this.url + '/login', model);
  }

  logout() {
    return this.http.post(this.url + '/logout', {});
  }

  lookup() {
    return this.http.get(this.url + '/lookup');
  }

  changePassword(model) {
    return this.http.post(this.url + '/changepassword', model);
  }

  toggleStatus(id) {
    return this.http.post(this.url + '/togglestatus', { id: id });
  }
}
