import { Component, ViewChild, OnInit } from '@angular/core';
import { TicketEditorService } from './ticket-editor.service';
import {MatAccordion} from '@angular/material/expansion';
import { fabric } from 'fabric';

@Component({
  selector: 'app-ticket-editor',
  templateUrl: './ticket-editor.component.html',
  styleUrls: ['./ticket-editor.component.less']
})
export class TicketEditorComponent implements  OnInit {
  data:any;
  canvas:any;
  textString: string;
  size:any ={
    width:500,
    height:500
  };
  types:any=[
  { id: 0, name: 'Text' },
  { id: 1, name: 'Image' }, 
  { id: 2, name: 'Bar Code' },
  { id: 3, name: 'Row Fix' },
  { id: 4, name: 'Seat Fix' }, 
  { id: 5, name: 'Fix Text' },
  { id: 6, name: 'Fix Image' },
  { id: 7, name: 'QrCode' }];
  selectedType = 0;
  fonts:any=[
  { id: 'Arial', name: 'Arial' },
  { id: 'verdana', name: 'Verdana' },
  { id: 'tahoma', name: 'Tahoma' }, 
  { id: 'PFIsotextPro-Reg', name: 'PFIsotextPro' }];
  selectedFont = 'Arial';
  OutputContent: string;
  constructor(private service: TicketEditorService) { }

  
  @ViewChild(MatAccordion) accordion: MatAccordion;

  private context: CanvasRenderingContext2D;

  ngOnInit(){
    this.canvas = new fabric.Canvas('canvasEl', {
      hoverCursor: 'pointer',
      selection: true,
      selectionBorderColor: 'blue'
    });
    
    this.canvas.setWidth(this.size.width);
    this.canvas.setHeight(this.size.height);
   // this.canvas.renderAll();

    this.service.getTemplates()
    .subscribe(response => {
      this.data = response as any;
    });
  }
  
  addText() {
    let textString = this.textString;
    let text = new fabric.IText(textString, {
      left: 10,
      top: 10,
      fontFamily: 'helvetica',
      angle: 0,
      fill: '#000000',
      scaleX: 0.5,
      scaleY: 0.5,
      fontWeight: '',
      hasRotatingPoint: true
    });
    this.extend(text, this.randomId());
    this.canvas.add(text);
    this.selectItemAfterAdded(text);
    this.textString = '';
}
 
extend(obj, id) {
  obj.toObject = (function (toObject) {
    return function () {
      return fabric.util.object.extend(toObject.call(this), {
        id: id
      });
    };
  })(obj.toObject);
}
randomId() {
  return Math.floor(Math.random() * 999999) + 1;
}
selectItemAfterAdded(obj) {
  this.canvas.discardActiveObject().renderAll();
  this.canvas.setActiveObject(obj);
}
  draw(templateId:string) {
    
  }
  resizeCanvas(){
    if(this.size.width != this.canvas.width){
      this.canvas.setWidth(this.size.width);
     
    }
    if(this.size.height != this.canvas.height){
      this.canvas.setHeight(this.size.height);
    }
    this.canvas.renderAll();
  }

}
