import { Component, OnInit, Input } from '@angular/core';
import { EventsService } from '../../events/events.service';
import { EventGroupsService } from '../../events/event-groups.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VirtualEventsService } from '../virtual-events.service';
import { DialogService } from '../../common/dialog.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';

@Component({
  selector: 'app-add-event-to-virtual-event',
  templateUrl: './add-event-to-virtual-event.component.html',
  styleUrls: ['./add-event-to-virtual-event.component.less']
})
export class AddEventToVirtualEventComponent implements OnInit {
  @Input() virtualEvent: any;
  groups: any[] = [];
  selection: any = {
    group: null
  };

  model: any = {
    virtualEventId: null,
    eventIds: []
  };

  constructor(
    private eventGroupService: EventGroupsService,
    private service: VirtualEventsService,
    private dialogService: DialogService,
    private modal: NgbActiveModal) { }

  ngOnInit() {
    this.setup();
    this.load();
  }

  setup() {
    this.model.virtualEventId = this.virtualEvent.id;
    this.virtualEvent.events.forEach(element => {
      this.model.eventIds.push(element.id);
    });
  }

  load() {
    this.eventGroupService.getAll({ page: 1, size: 200 })
      .subscribe(data => {
        this.groups = (data as any).events as any[];
        if (!!this.groups.length) {
          this.selection.group = this.groups[0];
        }
      });
  }

  isEventIncluded(evnt): boolean {
    return this.model.eventIds.indexOf(evnt.id) !== -1;
  }

  selectEvent(evnt) {
    const index = this.model.eventIds.indexOf(evnt.id);
    if (index === -1) {
      this.model.eventIds.push(evnt.id);
    } else {
      this.model.eventIds.splice(index, 1);
    }
  }

  save() {
    this.service.saveEventsForVirtualEvents(this.model)
      .subscribe(response => {
        this.modal.close(true);
      }, error => {
        this.dialogService.errorMessage(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
      });
  }

  close() {
    this.modal.close();
  }

}
