import { Component, OnInit, Input } from '@angular/core';
import { ShippingMethodsService } from '../../../shipping-methods/shipping-methods.service';
import { EventsService } from '../../events.service';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-shipping-method',
  templateUrl: './add-shipping-method.component.html',
  styleUrls: ['./add-shipping-method.component.less']
})
export class AddShippingMethodComponent implements OnInit {
  @Input() eventid;

  model: any = {
    id: 0,
    SelectedShippingMethodsIds: null
  };

  shippingMethods: any[];

  constructor(private service: ShippingMethodsService,
    private eventService: EventsService,
    private route: ActivatedRoute,
    public activeModal: NgbActiveModal
  ) {
    this.model.SelectedShippingMethodsIds = new Array<number>();
  }

  ngOnInit() {
    this.load();
  }

  load() {
    this.service.getEventShippingMethods(this.eventid)
      .subscribe(response => {
        this.shippingMethods = response as any[];
        if (this.shippingMethods.length > 0) {
          for (const method of this.shippingMethods) {
            if (method.isInEvent) {
              this.model.SelectedShippingMethodsIds.push(method.id);
            }
          }
        }
      });
  }


  selectShippingMethod(item: any) {
    if (item.isInEvent) {
      const index = this.model.SelectedShippingMethodsIds.indexOf(item.id);
      this.model.SelectedShippingMethodsIds.splice(index, 1);
      item.isInEvent = false;
    } else {
      item.isInEvent = true;
      this.model.SelectedShippingMethodsIds.push(item.id);
    }
  }
  saveShippingMethods() {
    this.model.id = this.eventid;
    this.eventService.saveShippingMethods(this.model).subscribe((data: any) => {
      this.activeModal.close(true);
    }, (error: any) => {
      console.log(error);
    });
  }

  closeModal() {
    this.activeModal.close();
  }
}
