import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {

  constructor(private cookieService: CookieService, private http: HttpClient) { }

  cookieName = 'eTicketsUser';
  isAuthenticated(): boolean {
    return this.cookieService.check(this.cookieName);
  }

  getCurrentUser() {
    const cookieValue = this.cookieService.get(this.cookieName);
    if (!cookieValue) {
      return null;
    }

    return JSON.parse(cookieValue).obj;
  }

  canUserAccessFeature(feature: any): boolean {
    const user = this.getCurrentUser();
    if (!user || !feature) {
      return false;
    }

    for (const itm of user.roles) {
      if ((itm.features & feature) === feature) {
        return true;
      }
    }

    return false;
  }

}
