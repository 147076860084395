import { Component, OnInit, Input } from '@angular/core';
import { ShippingMethodsService } from '../shipping-methods.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorHandler } from '../../common/httpErrorHandler';

@Component({
  selector: 'app-add-or-edit-shipping-method',
  templateUrl: './add-or-edit-shipping-method.component.html',
  styleUrls: ['./add-or-edit-shipping-method.component.less']
})
export class AddOrEditShippingMethodComponent implements OnInit {

  @Input() id;

  model: any = {
    id: 0,
    descriptionFirst: null,
    descriptionSecond: null,
    descriptionThird: null,
    priceFirst: null,
    priceSecond: null,
    requiresShippingInfo: false,
    isActive: true
  };
  errors: any[] = [];


  constructor(
    private service: ShippingMethodsService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (this.id !== 0) {
      this.getShippingMethodById(this.id);
    }
  }

  getShippingMethodById(id) {
    this.service.getShippigMethodById(id)
      .subscribe(method => {
        this.model = method;
      });
  }

  saveShippingMethod() {
    this.errors = [];
    this.service.saveOrUpdateShippingMethod(this.model)
      .subscribe(methods => {
        this.activeModal.close(true);
      },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
  }

  closeDialog() {
    this.activeModal.close();
  }

}
