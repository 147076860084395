import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  notify = new EventEmitter();

  constructor() { }
}
