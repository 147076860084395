import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from '../../events.service';

@Component({
  selector: 'app-set-event-row-status',
  templateUrl: './set-event-row-status.component.html',
  styleUrls: ['./set-event-row-status.component.less']
})
export class SetEventRowStatusComponent implements OnInit {

  @Input() row: any;
  @Input() zone: any;
  @Input() eventid: number;
  statuses: any = [];
  model: any = {
    eventId: null,
    zoneId: null,
    row: null,
    statusFrom: '',
    statusTo: ''
  };

  constructor(public activeModal: NgbActiveModal, private service: EventsService) { }

  ngOnInit() {
    this.model.eventId = this.eventid;
    this.model.zoneId = this.zone.id;
    this.model.row = this.row.row;
    this.load();
  }

  load() {
    this.service.getSeatStatuses()
      .subscribe(statuses => {
        this.statuses = statuses;
      });
  }

  save() {
    this.service.setRowStatus(this.model)
      .subscribe(success => {
        if (!!success) {
          this.activeModal.close(true);
        }
      });
  }

}
