import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../users.service';
import { DialogService } from '../../common/dialog.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';

@Component({
  selector: 'app-save-user',
  templateUrl: './save-user.component.html',
  styleUrls: ['./save-user.component.less']
})
export class SaveUserComponent implements OnInit {
  @Input() id: number;
  @Input() firmId: number;
  errors: any[] = [];
  lookup: any = {
    cities: [],
    roles: [],
    firms: []
  };

  user: any = {
    id: null,
    username: null,
    password: null,
    firmId: null,
    cityId: '',
    status: 2,
    isA4: false,
    roles: []
  };

  constructor(
    private modal: NgbActiveModal,
    private service: UsersService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.user.firmId = this.firmId;
    this.load();
  }

  load() {
    this.service.lookup()
      .subscribe(lookup => {
        this.lookup = lookup;
        if (!this.id) {
          return;
        }

        this.service.get(this.id)
          .subscribe(user => {
            this.user = user;
          });
      });
  }

  getRole(role) {
    if (!this.user.roles) {
      return null;
    }

    for (let index = 0; index < this.user.roles.length; index++) {
      const element = this.user.roles[index];
      if (element.id === role.id) {
        return element;
      }
    }

    return null;
  }

  isRoleIncluded(role) {
    return !!this.getRole(role);
  }

  selectRole(role) {
    const existingRole = this.getRole(role);
    if (!!existingRole) {
      this.user.roles.splice(existingRole, 1);
    } else {
      this.user.roles.push(role);
    }
  }

  save() {
    this.errors = [];
    this.service.post(this.user)
      .subscribe(response => {
        this.dialogService.successMessage('User saved successfully.');
        this.modal.close(true);
      }, error => {
        this.errors = HttpErrorHandler.retrieveErrors(error);
      });
  }

  close() {
    this.modal.close();
  }

}
