import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../../../reports/reports.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-event-seats-status-report',
  templateUrl: './event-seats-status-report.component.html',
  styleUrls: ['./event-seats-status-report.component.less']
})
export class EventSeatsStatusReportComponent implements OnInit {
  data: any = { stats: [] };

  model: any = {
    eventId: null
  };

  constructor(private service: ReportsService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.model.eventId = Number(paramMap.get('id'));
      if (!this.model.eventId) {
        return;
      }

      this.load();
    });
  }

  load() {
    this.service.getEventSeatStatsByStatus(this.model)
      .subscribe(data => {
        this.data = data;
      });
  }
}
