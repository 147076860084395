import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router, ActivationStart } from '@angular/router';
import { AuthService } from '../auth.service';

@Directive({
  selector: '[appMenuItem]'
})
export class MenuItemDirective implements OnInit {
  @Input() module: string;

  constructor(
    private element: ElementRef,
    private router: Router,
    private authService: AuthService) {

  }

  ngOnInit() {
    this.router.events.subscribe(data => {
      if (!(data instanceof ActivationStart)) {
        return;
      }

      this.setupMenuVisibility();
      const snapshot = data as ActivationStart;
      if (!snapshot.snapshot.routeConfig.data || !snapshot.snapshot.routeConfig.data.hasOwnProperty('module')) {
        return;
      }

      const module: string = snapshot.snapshot.routeConfig.data['module'];
      if (!module) {
        return;
      }

      this.element.nativeElement.className = module === this.module ? 'active' : '';
    });
  }

  setupMenuVisibility() {
    for (const itm of this.router.config) {
      if (itm.path === this.module) {
        const features = itm.data.features;
        if (!features) {
          break;
        }

        let show = false;
        for (const feature of features) {
          if (!!this.authService.canUserAccessFeature(feature)) {
            show = true;
          }
        }

        this.element.nativeElement.hidden = !show;
        break;
      }
    }
  }

}
