import { Component, OnInit } from '@angular/core';
import { EventsService } from '../events.service';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '../../common/dialog.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';
import { NotificationsService } from '../../common/notifications.service';
import { Constant } from '../../common/constants';
import { UtilsService } from '../../common/services/utils.service';
import { FilePair } from '../../common/data.service';

@Component({
  selector: 'app-event-tickets',
  templateUrl: './event-tickets.component.html',
  styleUrls: ['./event-tickets.component.less']
})
export class EventTicketsComponent implements OnInit {
  eventID: number;
  ticketData: any = null;
  file: File;
  model = {
    numberOfTickets: null,
    eventId: null
  };

  constructor(
    private utils: UtilsService,
    private route: ActivatedRoute,
    private service: EventsService,
    private dialogService: DialogService,
    private notificationService: NotificationsService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.eventID = Number(paramMap.get('id'));
      if (!this.eventID) {
        return;
      }

      this.model.eventId = this.eventID;
      this.load();
    });
  }

  load() {
    this.service.getTicketsDataForEvent(this.eventID)
      .subscribe(data => {
        this.ticketData = data;
      });
  }

  generateTickets() {
    this.service.generateTickets(this.model)
      .subscribe(data => {
        this.dialogService.successMessage('Tickets generated successfully!');
        this.model.numberOfTickets = null;
        this.load();
        this.notificationService.notify.emit(Constant.TICKETS_GENERATED_NOTIFICATION);
      }, error => {
        this.dialogService.errorMessage(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
      });
  }

  downloadTicketsURL() {
    return this.service.url + '/' + this.eventID + '/downloadtickets';
  }

  fileUploaded(files: FileList) {
    if (!files || !files.length) {
      return;
    }

    const file = files[0];
    const errors = this.utils.validateExcelFile(file);
    if (!!errors.length) {
      this.dialogService.errorMessage(errors[0]);
      return;
    }

    this.file = file;
  }

  uploadTicketNumbers() {
    const files = [];
    if (!!this.file) {
      files.push(new FilePair('file', this.file));
    }

    this.service.uploadTicketNumbers({ EventId: this.eventID }, files)
      .subscribe(
        count => {
          this.load();
          this.file = null;
          this.dialogService.successMessage(count + ' ticket numbers successfully uploaded.');
        },
        error => {
          this.dialogService.errorMessage(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
        });
  }

}
