import { Injectable } from '@angular/core';
import { DataService } from '../common/data.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class BundleService extends DataService {

  public url='api/bundles';
  constructor(http: HttpClient) {
    super(http);
    //, 'api/bundles'
  }
    getAllBundles(model: any) {
      return this.http.post(this.url + '/getallbundles', model);
    }
  
    getBundleById(id) {
      return this.http.get(this.url + '/' + id + '/getbundlebyid');
    }
  
    saveBundle(model) {
      return this.http.post(this.url + '/savebundle', model);
    }
    getBundleType(){
      return this.http.get(this.url + '/getBundleType')
    }
    lookup() {
      return this.http.get(this.url + '/lookup');
    }
  
  }
  