import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from '../../common/notifications.service';
import { Constant } from '../../common/constants';
import { HttpErrorHandler } from '../../common/httpErrorHandler';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  model: any = {
    username: null,
    password: null
  };
  errors: any[] = [];

  constructor(
    private router: Router,
    private userService: UsersService,
    private notificationService: NotificationsService) {

  }

  ngOnInit() {
  }

  login() {
    this.userService.login(this.model)
      .subscribe(response => {
        this.router.navigate(['']);
        this.notificationService.notify.emit(Constant.LOGIN_NOTIFICATION);
      },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
  }

}
