import { HttpErrorHandler } from '../../common/httpErrorHandler';
import { Component, OnInit } from '@angular/core';
import { EventsService } from '../events.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddShippingMethodComponent } from './add-event-shipping-method/add-shipping-method.component';
import { DialogService } from '../../common/dialog.service';
import { NotificationsService } from '../../common/notifications.service';
import { Constant } from '../../common/constants';

@Component({
  selector: 'app-event-shipping-methods',
  templateUrl: './event-shipping-methods.component.html',
  styleUrls: ['./event-shipping-methods.component.less']
})
export class EventShippingMethodsComponent implements OnInit {
  eventid: number;
  shippingMethods: any[] = [];
  constructor(private service: EventsService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private dialogService: DialogService,
    private notificationService: NotificationsService) { }

  ngOnInit() {
    this.loadShippingMethodsForEvent();
  }

  loadShippingMethodsForEvent() {
    this.route.paramMap.subscribe(paramMap => {
      this.eventid = Number(paramMap.get('id'));
      if (!this.eventid) {
        return;
      }
    });
    this.shippingMethods = [];
    this.service.getShippingMethods(this.eventid)
      .subscribe(methods => {
        this.shippingMethods = methods as any[];
      });
  }

  openModal() {
    const modalRef = this.modalService.open(AddShippingMethodComponent, { size: 'lg' });
    modalRef.componentInstance.eventid = this.eventid;
    modalRef.result.then(result => {
      if (!!result) {
        this.loadShippingMethodsForEvent();
        this.notificationService.notify.emit(Constant.TICKETS_GENERATED_NOTIFICATION);
      }
    });
  }

  openDeleteModal(id) {
    this.dialogService.confirmDialog('Are you sure you want to delete this shipping method for this event?')
      .then(result => {
        if (!!result) {
          this.service.deleteShippingMethodForEvent(id)
            .subscribe(response => {
              this.loadShippingMethodsForEvent();
              this.notificationService.notify.emit(Constant.TICKETS_GENERATED_NOTIFICATION);
            }, error => {
              this.dialogService.errorMessage(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
            });
        }
      });
  }
}
