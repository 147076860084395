import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-field-success',
  templateUrl: './field-success.component.html',
  styleUrls: ['./field-success.component.less']
})
export class FieldSuccessComponent implements OnInit {
  @Input() message: string;
  constructor() { }

  ngOnInit() {
  }
}
