import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../users/login/login.component';
import { EventGroupsComponent } from '../events/event-groups/event-groups.component';
import { AuthGuard } from '../common/auth-guard.service';
import { DashboardComponent } from '../common/dashboard/dashboard.component';
import { EventGroupDetailsComponent } from '../events/event-group-details/event-group-details.component';
import { EventDetailsComponent } from '../events/event-details/event-details.component';
import { ObjectsComponent } from '../objects/objects/objects.component';
import { ObjectDetailsComponent } from '../objects/object-details/object-details.component';
import { ReportsComponent } from '../reports/reports/reports.component';
import { ConfigurationComponent } from '../configuration/configuration.component';
import { ObjectMapDetailsComponent } from '../objects/object-details/object-map-details/object-map-details.component';
import { UsersComponent } from '../users/users/users.component';
import { UnauthorizedComponent } from '../common/unauthorized/unauthorized.component';
import { VirtualEventsComponent } from '../virtual-events/virtual-events.component';
import { FirmDetailsComponent } from '../firms/firm-details/firm-details.component';
import { EventBundlePriceComponent } from '../event-bundle-price/event-bundle-price.component';
import { BundleDetailsComponent } from '../event-bundle-price/bundle-details/bundle-details.component';


enum FeaturesEnum {
    Events = 1,
    Sales = 2,
    User = 4,
    Configuration = 8
}

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Events], module: 'dashboard' }
    },
    {
        path: 'event-groups',
        component: EventGroupsComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Events], module: 'events' }
    },
    {
        path: 'event-group/:id',
        component: EventGroupDetailsComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Events], module: 'events' }
    },
    {
        path: 'event/:id',
        component: EventDetailsComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Events], module: 'events' }
    },
    {
        path: 'objects',
        component: ObjectsComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Configuration], module: 'objects' }
    },
    {
        path: 'object/:id',
        component: ObjectDetailsComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Configuration], module: 'objects' }
    },
    {
        path: 'login',
        component: LoginComponent,
        data: { module: 'dashboard' }
    },
    {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Sales], module: 'reports' }
    },
    {
        path: 'object-map-details/:id',
        component: ObjectMapDetailsComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Configuration], module: 'objects' }
    },
    {
        path: 'configuration',
        component: ConfigurationComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Events], module: 'configuration' }
    },
    {
        path: 'users/:id',
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Configuration], module: 'configuration' }
    },
    {
        path: 'companies',
        component: FirmDetailsComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Configuration], module: 'companies' }
    },
    {
        path: 'bundles',
        component: EventBundlePriceComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Configuration], module: 'bundles' }
    },
    {
        path: 'bundle/:id',
        component: BundleDetailsComponent,
        canActivate: [AuthGuard],
        data: { features: [FeaturesEnum.Configuration], module: 'bundles' }
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule],
    declarations: []
})

export class AppRoutingModule { }
