import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service';
import { UtilsService } from '../../common/services/utils.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.less']
})

export class OrdersComponent implements OnInit {
  data: any = null;
  selection: any = { group: '' };
  lookup: any = { groups: [], firms: [] };
  model: any = {
    startDateObj: null,
    endDateObj: null,
    startDate: null,
    endDate: null,
    page: 1,
    size: 25,
    eventId: '',
    term: null
  };

  constructor(
    private utils: UtilsService,
    private service: ReportsService) { }

  ngOnInit() {
    this.setup();
    this.load();
  }

  setup() {
    this.model.startDateObj = this.utils.past(-1);
    this.model.endDateObj = this.utils.current();

    this.service.merchantSalesForPeriodLookup()
      .subscribe(data => {
        this.lookup = data;
        this.filter();
      });
  }

  groupChanged() {
    if (!this.selection.group ||
      !this.selection.group.events ||
      !this.selection.group.events.length) {
      this.model.eventId = '';
      return;
    }

    this.model.eventId = this.selection.group.events[0].id;
  }

  load() {
    this.model.startDate = this.utils.toDateString(this.model.startDateObj);
    this.model.endDate = this.utils.toDateString(this.model.endDateObj);
    this.service.getOrders(this.model)
      .subscribe(res => {
        this.data = res;
      });
  }

  filter() {
    this.model.page = 1;
    this.load();
  }

  getExportToExcelURL() {
    let url = this.service.url + '/exportorders?' +
      'startDate=' + this.utils.toDateString(this.model.startDateObj) +
      '&endDate=' + this.utils.toDateString(this.model.endDateObj) +
      '&page=1&size=' + this.data.total;

    if (!!this.model.eventId) {
      url += '&eventId=' + this.model.eventId;
    }

    if (!!this.model.term) {
      url += '&term=' + this.model.term;
    }

    return url;
  }

}
