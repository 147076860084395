import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportsService } from '../../reports/reports.service';
import { EventGroupsService } from '../../events/event-groups.service';
import { SaleStatisticsComponent } from '../../reports/sale-statistics/sale-statistics.component';
import { EventSaleStatisticsComponent } from '../../reports/event-sale-statistics/event-sale-statistics.component';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  @ViewChild('sr') salesReport: SaleStatisticsComponent;
  @ViewChild('esr') eventSalesReport: EventSaleStatisticsComponent;
  lookup: any = {
    reportTypes: [],
    events: []
  };

  filter: any = {
    type: null,
    event: null,
    start: null,
    end: null
  };

  constructor(
    private utils: UtilsService,
    private service: ReportsService,
    private groupsService: EventGroupsService) { }

  ngOnInit() {
    this.setup();
    this.load();
  }

  setup() {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    this.filter.start = this.utils.dateToStructure(today);
    this.filter.end = this.utils.dateToStructure(new Date());
  }

  load() {
    this.service.saleStatisticsLookup()
      .subscribe(data => {
        this.lookup = data;
        this.filter.type = this.lookup.reportTypes[0];
        this.reloadCharts();
      });
  }

  setReportType(type) {
    this.lookup.events = [];
    this.filter.event = null;
    this.filter.type = type;
    if (!!this.filter.type && this.filter.type.key > 0) {
      this.groupsService.get(this.filter.type.key)
        .subscribe(data => {
          this.lookup.events = (data as any).events;
        });
    }

    this.reloadCharts();
  }

  setEvent(evnt) {
    this.filter.event = evnt;
    this.reloadCharts();
  }

  reloadCharts() {
    if (!!this.salesReport) {
      this.salesReport.load();
    }

    if (!!this.eventSalesReport) {
      this.eventSalesReport.load();
    }
  }

  clearEvent() {
    this.filter.event = null;
    this.reloadCharts();
  }

}
