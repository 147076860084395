import { Component, OnInit } from '@angular/core';
import { FirmService } from '../firm.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaveFirmComponent } from '../save-firm/save-firm.component';
import { DialogService } from '../../common/dialog.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';
import { UsersComponent } from '../../users/users/users.component';

@Component({
  selector: 'app-firm-details',
  templateUrl: './firm-details.component.html',
  styleUrls: ['./firm-details.component.less']
})
export class FirmDetailsComponent implements OnInit {

  data: any;
  filter: any = {
    page: 1,
    size: 10,
    term: null
  };

  constructor(private service: FirmService,
    private modalService: NgbModal,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.data = {};
    this.data.records = [];
    this.load();
  }

  load() {
    this.service.getAllFirms(this.filter)
      .subscribe(firms => {
        this.data = firms;
      });
  }

  search() {
    this.filter.page = 1;
    this.load();
  }

  clear() {
    this.filter.page = 1;
    this.filter.term = null;
    this.load();
  }

  delete(id) {
    this.dialogService.confirmDialog('Are you sure you want to delete this company?')
      .then(response => {
        if (!!response) {
          this.service.delete(id)
            .subscribe(firms => {
              this.load();
            }, error => {
              this.dialogService.errorMessage(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
            });
        }
      });
  }

  edit(id) {
    const modalRef = this.modalService.open(SaveFirmComponent);
    modalRef.componentInstance.id = id;
    modalRef.result.then(result => {
      if (!!result) {
        this.data = [];
        this.load();
      }
    });
  }

  users(firm) {
    const modalRef = this.modalService.open(UsersComponent);
    modalRef.componentInstance.firm = firm;
    modalRef.result.then(result => {
      if (!!result) {
        this.load();
      }
    });
  }
}
