import { Component, OnInit, Input } from '@angular/core';
import { EventsService } from '../../events.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorHandler } from '../../../common/httpErrorHandler';

@Component({
  selector: 'app-set-event-row-price',
  templateUrl: './set-event-row-price.component.html',
  styleUrls: ['./set-event-row-price.component.less']
})
export class SetEventRowPriceComponent implements OnInit {
  @Input() period: any;
  @Input() eventid: number;
  @Input() zone: any;
  @Input() row: any;

  errors = [];
  model: any = {
    row: null,
    zoneId: null,
    eventId: null,
    priceFirst: null,
    priceSecond: null,
    sellPeriodId: null,
  };

  constructor(
    public activeModal: NgbActiveModal,
    private eventService: EventsService) {
  }

  ngOnInit() {
    this.model.sellPeriodId = this.period.id;
    this.model.eventId = this.eventid;
    this.model.zoneId = this.zone.id;
    this.model.row = this.row.row;
  }

  save() {
    this.errors = [];
    this.eventService.setRowPrice(this.model)
      .subscribe(result => {
        this.activeModal.close(true);
      }, error => {
        this.errors = HttpErrorHandler.retrieveErrors(error);
      });
  }

}
