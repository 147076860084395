import { Component, OnInit } from '@angular/core';
import { ObjectsService } from '../objects.service';

@Component({
  selector: 'app-objects',
  templateUrl: './objects.component.html',
  styleUrls: ['./objects.component.less']
})
export class ObjectsComponent implements OnInit {
  data: any;

  model: any = {
    page: 1,
    size: 10,
    term: null
  };


  constructor(private service: ObjectsService) { }

  ngOnInit() {
    this.data = {};
    this.data.objects = [];
    this.load();
  }

  load() {
    this.service.getAll(this.model)
      .subscribe(objects => {
        this.data = objects;
      });
  }

  search() {
    this.model.page = 1;
    this.load();
  }

  clear() {
    this.model.page = 1;
    this.model.term = null;
    this.load();
  }

}
