import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "../common/data.service";


@Injectable({
  providedIn: 'root'
})
export class TicketEditorService extends DataService {
    public url ="api/ticketdesign";
    constructor(http: HttpClient) {
        super(http);
      }
      getTemplates(){
        return this.http.get(this.url + '/gettemplates');
      }
      saveTemplates(model:any){
        return this.http.post(this.url + '/savetemplates',model);
      }
      getSelectedTemplate(templateId:string) {
        return this.http.get(this.url + '/' + templateId + '/getselectedtemplate');
      }
      saveSelectedTemplate(model:any){
        return this.http.post(this.url + '/saveselectedtemplate', model);
      }
      addNewField(model:any){
          return this.http.post(this.url + '/addnewfield', model);
      }
      deleteFieldFromTemplate(model:any, fieldId: string){
          return this.http.post(this.url + '/deletefieldfromtemplate/' + fieldId, model);
      }
      cloneTemplate(model:any, clonedTemplateName: string){
        return this.http.post(this.url + '/clonetemplate/' + clonedTemplateName, model);
    }
      uploadImage(img:any, name:string){
          return this.http.post(this.url + '/uploadimage/' + name,img);
      }
}