import { Component, OnInit } from '@angular/core';
import { SellPeriodsService } from '../sell-periods.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddOrEditSellPeriodComponent } from '../add-or-edit-sell-period/add-or-edit-sell-period.component';
import { DialogService } from '../../common/dialog.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';

@Component({
  selector: 'app-sell-periods',
  templateUrl: './sell-periods.component.html',
  styleUrls: ['./sell-periods.component.less']
})
export class SellPeriodsComponent implements OnInit {
  data: any = {
    records: []
  };
  id: number;
  model: any = {
    page: 1,
    size: 10,
    term: null
  };

  constructor(
    private service: SellPeriodsService,
    private modalService: NgbModal,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.service.getAll(this.model)
      .subscribe(periods => {
        this.data = periods;
      });
  }

  search() {
    this.model.page = 1;
    this.load();
  }

  clear() {
    this.model.page = 1;
    this.model.term = null;
    this.load();
  }

  openModal(id) {
    const modalRef = this.modalService.open(AddOrEditSellPeriodComponent);
    modalRef.componentInstance.id = id;
    modalRef.result.then(result => {
      if (!!result) {
        this.load();
      }
    });
  }

  openDeleteModal(id) {
    this.dialogService.confirmDialog('Are you sure you want to delete this sell period?')
      .then(result => {
        if (!!result) {
          this.service.deleteSellPeriod(id)
            .subscribe(methods => {
              this.data = [];
              this.load();
            }, error => {
              this.dialogService.errorMessage(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
            });
        }
      });
  }
}
