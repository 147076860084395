import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr((size * -1), size);
  }

  getPublicUrl(relativePath) {
    return (window as any).configuration.publicWebUrl + relativePath;
  }

  getTicketUrl(relativePath) {
    return (window as any).configuration.ticketUrl + 'Ticket' + relativePath + '.jpg';
  }

  validateFile(file: File): any[] {
    const errors = [];
    if (file.size / 1024 / 1024 > 2) {
      errors.push('You are allowed to upload a JPG file with maximum size of 2MB.');
    }

    return errors;
  }

  validateExcelFile(file: File): any[] {
    const errors = [];
    return errors;
  }

  dateToStructure(date: Date): any {
    return {
      day: date.getDate(),
      year: date.getFullYear(),
      month: date.getMonth() + 1
    };
  }

  structureToString(date: NgbDate): any {
    return date.year.toString() + this.padLeft(date.month.toString(), '0', 2) + this.padLeft(date.day.toString(), '0', 2);
  }

  fromDate(date: Date): NgbDate {
    return new NgbDate(date.getFullYear(), (date.getMonth() + 1), date.getDate());
  }

  current(): NgbDate {
    return this.fromDate(new Date());
  }

  past(month: number): NgbDate {
    const today = new Date();
    today.setMonth(today.getMonth() + month);
    return this.fromDate(today);
  }

  toDateString(date: NgbDate): string {
    return `${date.year}-${date.month}-${date.day}`;
  }
  validatePdfFile(file: File): any[] {
    const errors = [];
    if(file){
      const extension = file.name.split('.')[1].toLowerCase();
      if ( "pdf" !== extension.toLowerCase() ) {
          errors.push('You are allowed to upload only PDF files ')
      }
    }
    else{
      errors.push('File was not upload successfuly. Please try again.')
    }
    return errors;
  }
}
