import { Injectable } from '@angular/core';
import { DataService } from '../common/data.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EventGroupsService extends DataService {
  public url ='api/eventgroups';

  constructor(http: HttpClient) {
    super(http);
    //, 'api/eventgroups'
  }

  getPrioritiesAndTypes() {
    return this.http.get(this.url + '/getprioritiesandtypes');
  }

  lookup() {
    return this.http.get(this.url + '/lookup');
  }
  
  getEventGroupsParents() {
    return this.http.get(this.url + '/geteventgroupsparents');
  }
 
}
