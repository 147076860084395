import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DataService {
  url: string;

  constructor(public http: HttpClient) {
    // this.url = url;
  }

  getAll(params?: any) {
    return this.getWithParams(this.url, params);
  }

  getWithParams(url: string, params?: any) {
    let completeUrl = url;
    if (!!params) {
      completeUrl += '?';
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const val = params[key];
          if (!!val) {
            completeUrl += key + '=' + val + '&';
          }
        }
      }
    }

    if (completeUrl.endsWith('&')) {
      completeUrl = completeUrl.substring(0, completeUrl.length - 1);
    }

    return this.http.get(completeUrl);
  }

  get(id: any) {
    const completeUrl = this.url + '/' + id;
    return this.http.get(completeUrl);
  }

  post(model) {
    return this.http.post(this.url, model);
  }

  delete(id) {
    return this.http.delete(this.url + '/' + id);
  }

  postFile(url: string, model: any, files: FilePair[]) {
    const formData: FormData = new FormData();
    formData.append('model', JSON.stringify(model));
    files.forEach((file: FilePair, ndx: number) => {
      if (!file) {
        return;
      }

      formData.append(file.key, file.file, file.file.name);
    });

    return this.http.post(url, formData);
  }

  downloadDoc(url: string, model: any) {
    this.http.post(url, model, { responseType: 'blob', observe: 'response' }).subscribe(response => {
      const header: string = response.headers.get('content-disposition');
      const filetype = response.headers.get('content-type');
      let filename = 'file';
      const ndx = header.indexOf('filename=');
      if (ndx !== -1) {
        filename = header.substr(ndx + 9, header.length);
      }

      return this.downLoadFile(response.body, filetype, filename);
    });
  }

  downLoadFile(data: any, filetype: string, filename: string) {
    const blob = new Blob([data], { type: filetype });
    const url = window.URL.createObjectURL(blob);
    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
    return url;

  }

}

export class FilePair {
  key: string;
  file: File;

  constructor(key: string, file: File) {
    this.key = key;
    this.file = file;
  }
}
