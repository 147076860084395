import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '../../common/dialog.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.less']
})
export class ChangePasswordComponent implements OnInit {
  errors: any[] = [];
  model: any = {
    oldPassword: null,
    newPassword: null,
    confirmPassword: null
  };

  constructor(
    private service: UsersService,
    private modal: NgbActiveModal,
    private dialogService: DialogService) { }

  ngOnInit() {
  }

  save() {
    this.errors = [];
    this.service.changePassword(this.model)
      .subscribe(
        response => {
          this.dialogService.successMessage('Password successfully changed.');
          this.modal.close(true);
        },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
  }

  close() {
    this.modal.close();
  }

}
