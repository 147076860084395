import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/common/dialog.service';
import { HttpErrorHandler } from 'src/app/common/httpErrorHandler';
import { EventGroupsService } from 'src/app/events/event-groups.service';
import { EventsService } from 'src/app/events/events.service';
import { SellPeriodsService } from 'src/app/sell-periods/sell-periods.service';
import { Bundle } from '../bundle.model';
import { DatePipe } from '@angular/common';


import { BundleService } from '../bundle.service';

@Component({
  selector: 'app-bundle-details',
  templateUrl: './bundle-details.component.html',
  styleUrls: ['./bundle-details.component.less']
})
export class BundleDetailsComponent implements OnInit {
  selection: any = [];
  data: any = {
    events: []
  };
  bundle: any = {};
 
  filter: any = {
    page: 1,
    size: 20,
    term: null,
    state: null,
    category: 0
  };
   bundleName = '';
   bundleType = '';
   value: number;
   validFrom :Date;
   validTo : Date;
   zones: any[] = [];
   periods: any[] = [];
   bundleid: string;
   errors: any[] = [];
   

  public bundleForm: FormGroup;

  constructor( 
    private eventGroupsService: EventGroupsService, 
    private eventService: EventsService,
    private bundleService: BundleService,
    private fb: FormBuilder,
    private sellPeriodService: SellPeriodsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private datePipe: DatePipe) { 
      const items = [];
      
      this.bundleForm = this.fb.group({
        'id': new FormControl(this.bundleid),
        'bundleName' : new FormControl(this.bundleName, Validators.required),
        'bundleType': new FormControl(this.bundleType, Validators.required),
        'value': new FormControl(this.value, Validators.required),
        'validFrom': new FormControl(this.validFrom),
        'validTo': new FormControl(this.validTo),
        details: this.fb.array(items)
      });
    }
    
  bundleTypes = [];
  group: any;

  ngOnInit() {

    this.load();
  }
  load(){

    this.eventGroupsService.getAll(this.filter)
      .subscribe(response => {
        this.data = response as any;
      });
    this.bundleService.getBundleType()
      .subscribe(response =>{
        this.bundleTypes = response as any;
      });
      this.sellPeriodService.getAll({ page: 1, size: 1 })
      .subscribe(periods => {
        this.periods = periods['records'] || [];
        if (!this.periods || !this.periods.length) {
          return;
        }
        
        this.route.paramMap.subscribe(paramMap => {
          this.bundleid = String(paramMap.get('id'));
          if (this.bundleid == 'new') {
            return;
          }

          this.bundleService.getBundleById(this.bundleid)
          .subscribe(model => {
            this.bundle = model;
            let items = [];
            this.selection=[]
            this.bundle.details.forEach(element => {
              let eventGroupValid = null;
              let eventValid = null;
              let zoneValid = null;
              let allZones = null;

              this.data.events.forEach(eg => {
                if(element.event.eventGroupId == eg.eventGroup.id) {
                    eventGroupValid = eg.eventGroup;
                    eventGroupValid.events.forEach(ev => {
                      if(ev.id == element.event.id)
                      {
                        eventValid = ev
                        this.eventService.getEventZones(eventValid.id, this.periods[0].id)
                        .subscribe((zones: any[]) => {
                          allZones = zones
                          zones.forEach(z=>{
                            if (!element.zone){
                              return;
                            }
                            if(z.id == element.zone.id)
                              zoneValid = z;
                          });

                          this.selection.push(
                            {
                              eventGroup: eventGroupValid,
                              event: eventValid,
                              zone: zoneValid,
                              zones: allZones,
                              period: null
                            });
                            
                            items.push(
                              this.createItem()
                            );
                            
                            this.bundleForm.setControl('details',this.fb.array(items))
                        });
                      }
                    });
                  }
              });
            });
            this.bundleForm.patchValue({
              'id': this.bundleid,
              'bundleName': this.bundle.bundleName,
              'bundleType': this.bundle.bundleType.key,
              'value': this.bundle.value,
              'validFrom': this.bundle.validFromStruct,
              'validTo': this.bundle.validToStruct
            });
          });
      });
    });
    }
    addItem(){
      const details = this.bundleForm.get('details') as FormArray;
      details.push(this.createItem());
      
      this.selection.push(
        {
          eventGroup: null,
          event: null,
          zone: null,
          period: null
        }
      );
    }
    createItem(): FormGroup {
      return this.fb.group({
        eventGroup: [],
        event: [],
        zone: []
      });
    }

    loadZones(index: number) {
      if (!this.selection[index].event || !this.periods[0]) {
        return;
      }
      this.resetZones(index);
      this.eventService.getEventZones(this.selection[index].event.id, this.periods[0].id)
        .subscribe(zones => {
          this.selection[index].zones = zones as any[];
        });
    }
    save(){
      let validFrom:Date = null;
      if(this.bundleForm.value['validFrom'] != null){
        validFrom = new Date(this.bundleForm.value['validFrom'].year + '/' + this.bundleForm.value['validFrom'].month + '/' + this.bundleForm.value['validFrom'].day);
      }
      let validTo: Date = null;
      if(this.bundleForm.value['validTo'] != null){
       validTo = new Date(this.bundleForm.value['validTo'].year + '/' + this.bundleForm.value['validTo'].month + '/' + this.bundleForm.value['validTo'].day)
      }
      const newBundle = new Bundle(
        this.bundleForm.value['bundleName'],
        this.bundleForm.value['bundleType'],
        this.bundleForm.value['value'],
        this.bundleForm.value['details'],
        this.bundleForm.value['id'],
        validFrom,
        validTo
      );
      this.bundleService.saveBundle(newBundle)
      .subscribe(
        model => {
          if (!this.bundleid) {
            this.router.navigateByUrl('/bundle/' + model);
          }

          this.dialogService.successMessage('Bundle succesfully saved.');
          window.scrollTo(0, 0);
        },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
    }
    resetEvents(ind: number){
      //this.data.events.events={};
      this.selection[ind].event=[];
      this.resetZones(ind);
    }
    resetZones(ind: number){
      this.selection[ind].zone = [];
    }
    delete(index: number){
      const details = this.bundleForm.get('details') as FormArray;
      //delete this.selection[index];
      if (index !== -1) {
        this.selection.splice(index, 1);
    } 
      details.removeAt(index);
     
      
    }
  }