import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService, FilePair } from '../common/data.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectsService extends DataService {
  public url = 'api/objects';
  constructor(http: HttpClient) {
    super(http);
    //, 'api/objects'
  }

  getMapsForObject(objectid) {
    return this.http.get(this.url + '/' + objectid + '/maps');
  }

  getObjectMap(id) {
    return this.http.get(this.url + '/map/' + id);
  }

  saveMapForObject(model: any, files: FilePair[]) {
    return super.postFile(this.url + '/saveMapForObject', model, files);
  }

  saveObject(model: any, files: FilePair[]) {
    return super.postFile(this.url + '/saveObject', model, files);
  }
  deleteObjectMap(objectMapid) {
    return this.http.get(this.url + '/' + objectMapid + '/deleteObjectMap');
  }
  getObjectMapZone(model) {
    return this.http.post(this.url + '/getObjecMapZones', model);
  }
  getSeatsForZone(zoneseatid) {
    return this.http.get(this.url + '/' + zoneseatid + '/getobjectseatsforzone');
  }
  saveObjectMapZone(model) {
    return this.http.post(this.url + '/saveobjectmapzone', model);
  }
  getObjectMapZoneById(objectMapZoneId) {
    return this.http.get(this.url + '/' + objectMapZoneId + '/getobjectmapzonebyid');
  }
  saveRowForObjectMapZone(model) {
    return this.http.post(this.url + '/saverowforobjectmapzone', model);
  }
  deleteObjectMapZone(objectMapZoneId) {
    return this.http.get(this.url + '/' + objectMapZoneId + '/deleteobjectmapzone');
  }
  deleteObjectZoneSeat(seatId) {
    return this.http.get(this.url + '/' + seatId + '/deletezoneseat');
  }
  deleteRowForZone(rownumber, zoneId) {
    return this.http.get(this.url + '/' + rownumber + '/deletezonerow/' + zoneId);
  }
  getNotNumberedSeatsCount(objectMapZoneId) {
    return this.http.get(this.url + '/' + objectMapZoneId + '/getnotnumberedseats');
  }
  addNewSeatsInNotNumeratedRow(model) {
    return this.http.post(this.url + '/addnewseatsinnotnumeratedrow', model);
  }
  addNewSeatInRow(model) {
    return this.http.post(this.url + '/addnewseatinrow', model);
  }

}
