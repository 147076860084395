export class Bundle{
    public id: string;
    public bundleName: string;
    public validFrom: Date;
    public validTo: Date;
    public type: number;
    public value: number;
    
    public details: any [];

    constructor(bundleName: string, type:number, value:number,details: any [], id?: string, validFrom?: Date, validTo?: Date){
        this.id=id;
        this.bundleName = bundleName;
        this.type = type;
        this.value =  value;
        this.details = details;
        this.validFrom = validFrom;
        this.validTo = validTo;


    }
}