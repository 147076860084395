import { Component, OnInit } from '@angular/core';
import { BundleService } from './bundle.service';

@Component({
  selector: 'app-event-bundle-price',
  templateUrl: './event-bundle-price.component.html',
  styleUrls: ['./event-bundle-price.component.less']
})
export class EventBundlePriceComponent implements OnInit {
  data: any;
  filter: any = {
    page: 1,
    size: 10,
    term: null,
    state: null
  };
  lookup: any = {
    states: []
  };

  constructor(private service: BundleService) { }

  ngOnInit() {
    this.data = {};
    this.data.records = [];
    this.service.lookup()
    .subscribe(lookup => {
      this.lookup = lookup as any;
      this.filter.state = this.lookup.states[0].key;
      this.load();
    });
  }

  load() {
    this.service.getAllBundles(this.filter)
      .subscribe(bundles => {
        this.data = bundles;
      });
  }

  search() {
    this.filter.page = 1;
    this.load();
  }

  clear() {
    this.filter.page = 1;
    this.filter.term = null;
    this.load();
  }



 
}
