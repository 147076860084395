import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorHandler } from '../../common/httpErrorHandler';
import { FirmService } from '../firm.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-save-firm',
  templateUrl: './save-firm.component.html',
  styleUrls: ['./save-firm.component.less']
})
export class SaveFirmComponent implements OnInit {
  @Input() id;
  Cities: any[];
  errors: any[] = [];

  model: any = {
    id: 0,
    name: null,
    alias: null,
    address: null,
    cityId: '',
    sortOrder: null
  };

  constructor(private service: FirmService,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.loadCities();
    if (this.id !== 0) {
      this.getFirmById();
    }
  }

  loadCities() {
    this.service.getAllCities()
      .subscribe(cities => {
        this.Cities = cities as any[];
      });
  }

  closeDialog() {
    this.activeModal.close();
  }

  getFirmById() {
    this.service.getFirmById(this.id)
      .subscribe(item => {
        this.model = item;
      });
  }

  saveFirm() {
    this.errors = [];
    this.service.saveFirm(this.model)
      .subscribe(methods => {
        this.activeModal.close(true);
      },
        error => {
          this.errors = HttpErrorHandler.retrieveErrors(error);
        });
  }

}
