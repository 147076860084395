import { Component, OnInit, Input } from '@angular/core';
import { VirtualEventsService } from '../virtual-events.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '../../common/dialog.service';
import { HttpErrorHandler } from '../../common/httpErrorHandler';

@Component({
  selector: 'app-add-reader-to-virtual-event',
  templateUrl: './add-reader-to-virtual-event.component.html',
  styleUrls: ['./add-reader-to-virtual-event.component.less']
})
export class AddReaderToVirtualEventComponent implements OnInit {
  @Input() virtualEvent: any;
  editmode = false;
  data: any = {};
  model: any = {
    virtualEventId: null,
    readerIds: []
  };

  reader: any = {
    name: null,
    password: null
  };

  filter: any = {
    page: 1,
    size: 10,
    term: null
  };

  constructor(
    private service: VirtualEventsService,
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.setup();
    this.load();
  }

  setup() {
    this.model.virtualEventId = this.virtualEvent.id;
    for (const itm of this.virtualEvent.readers) {
      this.model.readerIds.push(itm.id);
    }
  }

  load() {
    this.service.getAllReaders(this.filter)
      .subscribe(data => {
        this.data = data;
      });
  }

  isReaderIncluded(reader) {
    return this.model.readerIds.indexOf(reader.id) !== -1;
  }

  selectReader(reader) {
    const ndx = this.model.readerIds.indexOf(reader.id);
    if (ndx === -1) {
      this.model.readerIds.push(reader.id);
    } else {
      this.model.readerIds.splice(ndx, 1);
    }
  }

  save() {
    this.service.saveReadersForVirtualEvent(this.model)
      .subscribe(response => {
        this.modal.close(true);
      });
  }

  close() {
    this.modal.close();
  }

  newReader() {
    this.editmode = true;
  }

  saveReader() {
    this.service.saveReader(this.reader)
      .subscribe(response => {
        this.data.readers.unshift(response);
        this.selectReader(response);
        this.cancel();
      }, error => {
        this.dialogService.errorMessage(HttpErrorHandler.retrieveErrorsAsSingleMessage(error));
      });
  }

  cancel() {
    this.reader.name = null;
    this.reader.password = null;
    this.editmode = false;
  }

}
