import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DataService } from '../common/data.service';


@Injectable({
  providedIn: 'root'
})

export class ShippingMethodsService extends DataService {

  public url='api/shippingmethods';
  constructor(http: HttpClient) {
    super(http);
    //, 'api/shippingmethods'
  }

  getEventShippingMethods(eventid) {
    return this.http.get(this.url + '/' + eventid + '/geteventshippingmethods');
  }
  saveOrUpdateShippingMethod(model: any) {
    return this.http.post(this.url + '/saveShippingMethod', model);
  }
  getShippigMethodById(shippingmethodid) {
    return this.http.get(this.url + '/' + shippingmethodid + '/getmethodbyid');
  }
  deleteShippingMethod(shippingmethodid) {
    return this.http.get(this.url + '/' + shippingmethodid + '/deleteShippigMethod');
  }
}
